<template>
  <nav ref="root" class="navigation" aria-modal="true" @keydown.tab.shift.exact.prevent="moveFocusBack()" @keydown.tab.exact.prevent="moveFocusForward()" @click.stop="closeNavigation">
    <div class="navigation__inner">
      <div class="navigation__content">
        <div class="navigation__header">
          <button v-if="!desktop" aria-pressed="false" class="navigation-control navigation__close" @click="closeNavigation">
            <span class="navigation-control__text">{{ closePrimaryNavigationText }}</span>
            <CrossIcon class="navigation-control__icon navigation__close-icon" />
          </button>
        </div>
        <div class="navigation__body">
          <div class="navigation__panes">
            <div class="navigation__pane">
              <div class="navigation__pane-inner navigation__pane--primary">
                <NavigationList
                    :max-depth="desktop ? 1 : 2"
                    :item-class="`navigation__primary-top-item`"
                    :link-class="`navigation__primary-top-link`"
                    :items="primaryItemsTopLevel"
                    :click="handleLinkWithChildrenClick"
                    :item-click="itemClick"
                    class="navigation__primary-top"
                    second-level-class="navigation__primary-second"
                    second-level-item-class="navigation__primary-second-item"
                    second-level-link-class="navigation__primary-second-link"
                    :is-primary-navigation-open="isPrimaryNavigationOpen"
                    :open-nav-index="openNavIndex"
                >
                  <template slot="link" slot-scope="{ node,index }">
                    {{ node.title }}
                    <ArrowFilled v-if="node.children.length" :class="`navigation__primary-top-icon navigation__primary-arrow-${index}`" />
                    <ArrowMobile v-else class="navigation__primary-top-icon" />
                  </template>
                </NavigationList>
              </div>
            </div>

            <div v-if="outputSecondaryPane && desktop" class="navigation__pane navigation__pane--secondary">
              <div class="navigation__pane-inner">
                <div v-if="title && secondaryText && primaryItemsSecondLevel.length <= 3" class="navigation__primary-second-meta">
                  <h3 class="navigation__primary-second-meta-title">{{ title }}</h3>
                  <p class="navigation__primary-second-meta-desc">{{ secondaryText }}</p>
                </div>

                <NavigationList
                    :max-depth="1"
                    :item-class="`navigation__primary-second-item`"
                    :link-class="`navigation__primary-second-link`"
                    :items="primaryItemsSecondLevel"
                    :item-click="itemClick"
                    class="navigation__primary-second"
                >
                  <template slot="link" slot-scope="{ node,index }">
                    <div class="navigation__primary-second--wrapper" @mouseover="hovered = index" @mouseleave="hovered = null">
                      <section class="navigation__primary-second-image" :data-index="index" :class="{'overlay':node.isOverlayItem}">
                        <img
                            v-if="node.image"
                            :alt="node.image.description"
                            :src="getImageSizedUrl(node.image.url,!!node.isOverlayItem)"
                            class="simple-slider__image"
                        >
                        <div v-if="node.image && node.image.url && !node.isOverlayItem " :id="`primary-second-overlay_${index}`" class="navigation__primary-second-overlay" :class="{'active':hovered !== null && hovered !== index}" />
                        <div v-if="node.image && node.image.url && !node.isOverlayItem" class="navigation__primary-second-banner">
                          <p v-if="node.subtext" class="navigation__primary-second-banner-subtext">{{ node.subtext }}</p>
                          <span>View</span>
                        </div>
                      </section>
                      <section class="navigation__primary-second-group" :data-index="index" :class="{'about-style':!node.image}">
                        <span v-if="!node.isOverlayItem" class="navigation__primary-second-title">{{ node.title }}<ArrowMobile size="18" class="navigation__primary-second-title-arrow" /></span>
                        <span v-if="!node.isOverlayItem" class="navigation__primary-second-secondary">{{ node.secondaryText }} </span>
                        <span v-if="!node.isOverlayItem" class="navigation__primary-second-tertiary">{{ node.tertiaryText }}</span>
                      </section>
                    </div>
                  </template>
                </NavigationList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import debounce from 'lodash.debounce';
import NavigationList from '~/components/elements/NavigationList';
import { focusElements } from '~/components/mixins/focus-elements.js';
import CrossIcon from '~/assets/icons/close.svg?inline';
import ArrowMobile from '~/assets/icons/icon_misc_arrow_lined.svg?inline';
import ArrowFilled from '~/assets/icons/arrow-filled.svg?inline';
import { LG } from '~/utils/constants';
import { BREAKPOINTS, BREAKPOINT_MD, BREAKPOINT_LG } from '~/utils/constants';

export default {
  components: {
    NavigationList,
    CrossIcon,
    ArrowMobile,
    ArrowFilled,
  },
  mixins: [focusElements],
  props: {
    closeNavigation: { type: Function, required: true },
    openNavigationLevel: { type: Function, required: true },
    openTopLevel: { type: Function, required: true },
    primaryItemsTopLevel: { type: Array, required: true },
    primaryItemsSecondLevel: { type: Array, required: true },
    handleLinkWithChildrenClick: { type: Function, required: true },
    secondaryItems: {
      type: Array,
      default() {
        return [];
      },
    },
    address: { type: Object, required: true },
    book: { type: Object, required: true },
    closePrimaryNavigationText: { type: String, required: true },
    backToMenuText: { type: String, required: true },
    isPrimaryNavigationOpen: { type: Boolean, default: false },
    title: { type: String, default: null },
    secondaryText: { type: String, default: null },
    openNavIndex: { type: Number, default: -1 },

  },
  data() {
    return {
      desktop: false,
      hidePrimaryPane: false,
      hovered: null,
      imageSizes: {
        default: [620, 432],
        sizes: [
          { breakpoint: BREAKPOINTS[BREAKPOINT_MD], width: 92, height: 92 },
          { breakpoint: BREAKPOINTS[BREAKPOINT_LG], width: 244, height: 185 },
        ],
      },
    };
  },
  computed: {
    outputPrimaryPane() {
      this.defineFocusableElements();

      if (this.desktop || this.hidePrimaryPane) {
        return false;
      }

      return true;
    },
    outputSecondaryPane() {
      this.defineFocusableElements();
      return this.primaryItemsSecondLevel.length > 0;
    },
    secondaryActiveClass() {
      return {
        'navigation__panes--secondary-active': this.primaryItemsSecondLevel.length > 0,
      };
    },

  },
  watch: {

    outputSecondaryPane(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        setTimeout(() => {
          this.hidePrimaryPane = true;
        }, 300);
      }

      this.hidePrimaryPane = false;
    },
    isPrimaryNavigationOpen() {
      this.handleNavToggle = debounce(() => this.resetArrowClass(), 50);
      this.handleNavToggle();
    },
    openNavIndex(newValue, oldValue) {
      if (newValue !== oldValue && newValue >=0 ) {
        this.handleNavToggle = debounce(() => this.resetArrowClass(), 50);
        this.handleNavToggle();
      }
    },
  },
  mounted() {
    this.$scrollLock.disablePageScroll(this.$el);
    this.resizeMethod = debounce(() => this.handleResponsiveSwitch(), 100);
    this.handleResponsiveSwitch();

    document.addEventListener('keydown', this.closeOnEscape);
    window.addEventListener('resize', this.resizeMethod);

    this.defineFocusableElements();
  },
  destroyed() {
    this.$scrollLock.enablePageScroll(this.$el);

    document.removeEventListener('keydown', this.closeOnEscape);
    window.removeEventListener('resize', this.resizeMethod);
  },
  methods: {
    getImageSizedUrl(url, isOverlayItem) {
      const width = this.desktop ? (isOverlayItem ? 1.5*427 :2*244) : 92;
      const height = this.desktop ? (isOverlayItem ? 1.5*291 : 2*185) : 92;

      return `${url}?width=${width}&height=${height}&auto=format`;
    },
    resetArrowClass(newOpenNavIndex, newIsPrimaryNavigationOpen) {
      const items = this.primaryItemsTopLevel.length;
      for (let index = 0; index < items; index++) {
        const node = document.querySelector(`.navigation__primary-arrow-${index}`);
        if (node) {
          if (index === this.openNavIndex && this.isPrimaryNavigationOpen) {
            node.classList.add(`active`);
          } else {
            node.classList.remove(`active`);
          }
        }

      }
    },
    handleResponsiveSwitch() {
      if (window.innerWidth >= LG) {
        this.desktop = true;
      } else {
        this.desktop = false;
      }

      this.defineFocusableElements();
    },

    closeOnEscape(e) {
      if (e.keyCode === 27) {
        this.closeNavigation();
      }
    },
    itemClick(node) {
      if (node && node.url && node.children.length == 0) {
        this.currentPageClick(node.url);
      }
    },
    currentPageClick(url) {
      if (url && this.$router && this.$router.currentRoute && this.$router.currentRoute.path === url) {
        this.closeNavigation();
      }
    },
    defineFocusableElements() {
      this.$nextTick(()=>{
        const nodes = this.$el.querySelectorAll(this.FOCUSABLE_ELEMENTS);
        this.focusableElements = Object.keys(nodes).map((key) => nodes[key]);
        this.focusableElements[0].focus();
      });
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/components/_navigation.scss';
</style>
