<template>
  <ul>
    <NavigationItem
        v-for="(item, i) in items"
        :key="i"
        :class="[!(item.image && item.image.url) ? 'no-img' : '', itemClass,item.isOverlayItem && 'overlay']"
        :link-class="linkClass"
        :node="item"
        :max-depth="maxDepth"
        :depth="1"
        :click="click"
        :index="i"
        :item-click="itemClick"
        :link-active-class="linkActiveClass"
        :is-primary-navigation-open="isPrimaryNavigationOpen"
        :open-nav-index="openNavIndex"
        :second-level-class="secondLevelClass"
        :second-level-item-class="secondLevelItemClass"
        :second-level-link-class="secondLevelLinkClass"
    >
      <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" :index="i" />
      <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
        <slot :name="slot" v-bind="scope" :index="i" />
      </template>
    </NavigationItem>
  </ul>
</template>

<script>
import NavigationItem from '~/components/elements/NavigationItem';

export default {
  components: {
    NavigationItem,
  },
  props: {
    maxDepth: { type: Number, default: 5 },
    items: { type: Array, required: true },
    itemClass: { type: String, default: '' },
    linkClass: { type: String, default: '' },
    click: { type: Function, default: null },
    itemClick: { type: Function, default: null },
    linkActiveClass: { type: String, default: '' },
    isPrimaryNavigationOpen: { type: Boolean, default: false },
    openNavIndex: { type: Number, default: -1 },
    title: { type: String, default: null },
    secondaryText: { type: String, default: null },
    secondLevelClass: { type: String, default: '' },
    secondLevelItemClass: { type: String, default: '' },
    secondLevelLinkClass: { type: String, default: '' },

  },
  computed: {

  },
};
</script>
