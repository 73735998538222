<template>
  <li @click.stop="onItemClick">
    <a v-if="node.external" :id="`a_index_${index}`" tabindex="0" :class="getLinkClass" :href="node.url" target="_blank"><slot :node="node" name="link">{{ node.title }}</slot></a>
    <nuxt-link
        v-else-if="click"
        :id="`link_index_${index}`"
        :class="linkClass"
        :to="node.url"
        active-class="active"
        event=""
        @click.native="onLinkClick"
    >
      <slot :node="node" name="link">{{ node.title }}</slot>
    </nuxt-link>
    <nuxt-link v-else :class="linkClass" :to="node.url" active-class="active">
      <slot :node="node" name="link">{{ node.title }}</slot>
    </nuxt-link>

    <ul v-if="node.children
      && node.children.length
      && depth !== maxDepth
      && isPrimaryNavigationOpen
      && openNavIndex === index" :class="secondLevelClass"
    >
      <li
          v-for="(child, i) in node.children"
          :key="i"
          :class="secondLevelItemClass"
          @click.prevent="onItemClick"
      >
        <nuxt-link v-if="(!child.isOverlayItem)" :class="secondLevelLinkClass" :to="child.url" active-class="active"
            @click.native="onLinkClick"
        >
          <span v-if="child.image" :class="`${secondLevelClass}-image`">
            <KenticoResponsiveImage
                :alt="child.image.description"
                :src="child.image.url"
                :sizes="imageSizes"
            /></span>
          <section :class="`${secondLevelClass}-group`">
            <span :class="`${secondLevelClass}-title`">{{ child.title }}<ArrowMobile size="16" :class="`${secondLevelClass}-title-arrow`" /></span>
            <div v-if="child.image && child.image.url && child.subtext" :class="`${secondLevelClass}-mobile-banner`">
              <p v-if="child.subtext" :class="`${secondLevelClass}-mobile-banner-subtext`">{{ child.subtext }}</p>
            </div>
            <span v-if="!child.subtext" :class="`${secondLevelClass}-secondary`">{{ child.secondaryText }} </span>
            <span v-if="!child.subtext" :class="`${secondLevelClass}-tertiary`">{{ child.tertiaryText }}</span>
          </section>
        </nuxt-link>
      </li>
      <!-- </Node> -->
    </ul>
  </li>
</template>

<script>
import { clickedNavLink } from '~/utils/gtm-events.js';
import ArrowMobile from '~/assets/icons/icon_misc_arrow_lined.svg?inline';

export default {
  name: 'Node',
  components: { ArrowMobile },
  props: {
    index: { type: Number, default: 0 },
    maxDepth: { type: Number, required: true },
    depth: { type: Number, required: true },
    node: { type: Object, required: true },
    itemClick: { type: Function, default: null },
    linkClass: { type: String, default: '' },
    click: { type: Function, default: null },
    linkActiveClass: { type: String, default: '' },
    isPrimaryNavigationOpen: { type: Boolean, default: false },
    secondLevelClass: { type: String, default: '' },
    secondLevelItemClass: { type: String, default: '' },
    secondLevelLinkClass: { type: String, default: '' },
    openNavIndex: { type: Number, default: -1 },
  },
  data() {

    return {
      clickdIndex: -1,
      imageSizes: {
        default: [310, 216],
      },
    };
  },
  computed: {
    nextDepth() {
      return this.depth + 1;
    },
    getLinkClass() {

      return {
        '`${linkClass}`': true,
        'active': this.isPrimaryNavigationOpen && this.openNavIndex >= 0 && this.clickedIndex === this.openNavIndex,
      };
    },
  },
  methods: {
    onItemClick(e) {
      e.stopPropagation();
      if (this.itemClick) {
        const { node } = this;
        const idSplitArray = e.target.id.split('_');
        this.clickdIndex = idSplitArray[idSplitArray.length -1];
        this.itemClick(node, e, this.depth, this.clickdIndex);
      }
    },


    onLinkClick(e) {
      const { node } = this;
      const spacelessTitle = this.node.title.replace(/\s+/g, '');
      const action = `Clicked${spacelessTitle}Menu`;
      clickedNavLink(this.$gtm, action);


      if (this.click) {
        this.click(node, e, this.depth, this.index);
      } else if (this.external) {
        window.open(node.url);
      } else {
        this.$router.push({ path: node.url });
      }
    },
  },
};
</script>
