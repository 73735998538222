<template>
  <section class="crisis-notification-bar-container">
    <div v-if="crisisNotificationAlert && crisisNotificationAlert.body" class="crisis-notification-bar">
      <div class="crisis-notification-bar__copy">
        <div class="crisis-notification-bar__body" v-html="crisisNotificationAlert.body" />
        <a
            :href="crisisNotificationAlert.ctaLink"
            class="crisis-notification-bar__cta"
            :rel="linkRel"
            target="_blank"
        >
          {{ crisisNotificationAlert.ctaLabel }}
        </a>
      </div>
      
      <div class="crisis-notification-bar__close-btn" @click="closeCrisisAlert"><CloseIcon /></div>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { NAMESPACE, SET_CRISIS_NOTIFICATION_ALERT } from '~/store/sitewide';
import CloseIcon from '~/assets/icons/close.svg?inline';

const { mapGetters, mapActions } = createNamespacedHelpers(NAMESPACE);

export default {
  name: 'CrisisNotificationBar',
  components: {
    CloseIcon,
  },
  props: {
    template: { type: String, required: true },
  },
  data() {
    return {
      open: true,
    };
  },
  computed: {
    ...mapGetters(['crisisNotificationAlert']),
    linkRel() {
      const { origin } = window.location;
      const { ctaLink } = this.crisisNotificationAlert;
      return !ctaLink.includes(origin) ? 'noopener' : '';
    },
  },
  methods: {
    closeCrisisAlert() {
      this.SET_CRISIS_NOTIFICATION_ALERT({
        crisisNotificationAlert: {},
      });
    },
    ...mapActions([SET_CRISIS_NOTIFICATION_ALERT]),
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/components/_crisis_notification_bar.scss';
</style>
