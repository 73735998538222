<template>
  <footer class="footer">
    <button aria-label="top" class="footer__top" @click="scrollToTop">
      <Arrow class="footer__top-icon" />
      <a :href="`#${footerBackToTopAnchor}`" class="footer__top-text text--small">{{ footerBackToTopText }}</a>
      <Oval class="footer__top-bg" />
    </button>
    <section class="footer__main bg--dark-green">
      <FooterPrimary :menu-one-title="footerMenuOneTitle"
          :menu-one-items="footerMenuOneItems"
          :menu-two-title="footerMenuTwoTitle"
          :menu-two-items="footerMenuTwoItems"
          :menu-three-title="footerMenuThreeTitle"
          :menu-three-items="footerMenuThreeItems"
          :copyright="copyright"
          :contact-details-title="footerContactDetailsTitle"
          :address="address"
          :social-media="socialMedia"
      />
      <BlueBlob class="footer__primary-shape" />
    </section>
  </footer>
</template>

<style lang="scss">
@import '~/assets/scss/atomic/modules/_footer.scss';
</style>

<script>
import FooterPrimary from '~/components/components/FooterPrimary';

import BlueBlob from '~/assets/images/shapes/blue-blob.svg?inline';
import Oval from '~/assets/icons/oval.svg?inline';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('sitewide');

export default {
  components: {
    FooterPrimary,
    BlueBlob,
    Oval,
  },
  props: {},
  computed: {
    ...mapGetters([
      'newsletterTitle',
      'newsletterUserId',
      'newsletterListId',
      'newsletterEmailFieldPlaceholder',
      'newsletterSubmitButtonText',
      'footerBackToTopText',
      'footerBackToTopAnchor',
      'footerMenuOneTitle',
      'footerMenuOneItems',
      'footerMenuTwoTitle',
      'footerMenuTwoItems',
      'footerMenuThreeTitle',
      'footerMenuThreeItems',
      'footerContactDetailsTitle',
      'address',
      'copyright',
      'socialMedia',
    ]),
  },
  methods: {
    scrollToTop(event) {
      event.preventDefault();

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>
