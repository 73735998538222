<template>
  <div class="footer__primary">
    <div class="container">
      <div class="row">
        <div class="col-10 offset-1 col-lg-4 offset-lg-0"><LogoText class="footer__logo" /></div>
        <div class="col-10 offset-1 col-lg-8 offset-lg-0 accordion__wrapper">
          <div class="row">
            <div class="col-lg-4 footer__item--menu-one">
              <Accordion
                  :trigger-class="`footer__menu-title`"
                  :trigger-active-class="`footer__menu-title--active`"
                  :disable-lg="disableAccordionLG"
                  :panel-class="`footer__menu-panel`"
                  class="footer__accordion"
              >
                <template slot="trigger">
                  <span class="footer__menu-title-text">{{ menuOneTitle }}</span>
                  <Chevron class="footer__menu-title-icon" />
                </template>
                <template slot="content">
                  <nav role="navigation">
                    <NavigationList
                        :max-depth="1"
                        :item-class="`footer__menu-item`"
                        :link-class="`footer__menu-link`"
                        :items="menuOneItems"
                        class="footer__menu"
                    />
                  </nav>
                </template>
              </Accordion>
            </div>
            <div class="col-lg-4 footer__item--menu-two align-bottom">
              <Accordion
                  :trigger-class="`footer__menu-title`"
                  :trigger-active-class="`footer__menu-title--active`"
                  :disable-lg="disableAccordionLG"
                  :panel-class="`footer__menu-panel`"
                  class="footer__accordion footer__get-in-touch"
              >
                <template slot="trigger">
                  <span class="footer__menu-title-text">{{ contactDetailsTitle }}</span>
                  <Chevron class="footer__menu-title-icon" />
                </template>
                <template slot="content">
                  <Vcard v-bind="address" class="footer__address" />
                </template>
              </Accordion>
            </div>
            <div class="col-lg-4 footer__item--menu-three">
              <Accordion
                  :trigger-class="`footer__menu-title footer__menu-title--details`"
                  :trigger-active-class="`footer__menu-title--active`"
                  :panel-class="`footer__menu-panel`"
                  :disable-lg="disableAccordionLG"
                  class="footer__accordion footer__details"
              >
                <template slot="trigger">
                  <span class="footer__menu-title-text">{{ menuThreeTitle }}</span>
                  <Chevron class="footer__menu-title-icon" />
                </template>
                <template slot="content">
                  <NavigationList
                      :max-depth="1"
                      :item-class="`footer__menu-item`"
                      :link-class="`footer__menu-link`"
                      :items="menuThreeItems"
                      class="footer__menu footer__menu--three"
                  />
                </template>
              </Accordion>
            </div>
            <div class="col-lg-7 footer__item--copyright text-center text-left--desktop">
              <div class="footer__notes">
                <span class="footer__note">{{ copyright }}</span>
              </div>
            </div>
            <div class="col-lg-4 footer__item--social footer__social-media-wrapper">
              <ul class="footer__social-media">
                <li
                    v-for="(item, index) in socialMedia"
                    :key="index"
                    class="footer__social-media-item"
                >
                  <a
                      v-if="item.icon"
                      :href="item.url"
                      class="footer__social-media-link"
                      :data-name="item.networkName"
                      rel="noopener"
                      target="_blank"
                  >
                    <span class="footer__social-media-text">Find us on {{ item.networkName }}</span>
                    <component :is="getIcon(item.icon)" class="footer__social-media-icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { forceObject } from '~/utils/processor';
import { LG } from '~/utils/constants';

import NavigationList from '../elements/NavigationList';
import Vcard from '~/components/elements/Vcard';

import LogoText from '~/assets/icons/the-collective-text-logo.svg?inline';

import Youtube from '~/assets/icons/youtube.svg?inline';
import Instagram from '~/assets/icons/instagram.svg?inline';
import Twitter from '~/assets/icons/twitter.svg?inline';
import Facebook from '~/assets/icons/facebook.svg?inline';

export default {
  components: {
    NavigationList,
    LogoText,
    Vcard,
    Youtube,
    Instagram,
    Twitter,
    Facebook,
  },

  props: {
    address: { type: Object, required: true },
    contactDetailsTitle: { type: String, required: true, default: 'Get in Touch' },
    menuOneTitle: { type: String, required: true },
    menuOneItems: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    menuTwoTitle: { type: String, default: '' },
    menuTwoItems: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    menuThreeTitle: { type: String, default: 'Details' },
    menuThreeItems: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    socialMedia: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    disableAccordionLG: { type: Boolean, default: true },
    copyright: { type: String, required: true, default: 'The Collective 2019' },
  },
  mounted() {
    window.addEventListener('resize', this.tabIndex);
    this.tabIndex();
    this.urlTest();
  },
  methods: {
    getIcon(iconArray) {
      const iconObj = forceObject(iconArray);

      return iconObj.codename;
    },
    urlTest() {
      const element = document.querySelectorAll('.footer__social-media-link');

      element.forEach(function(element) {

        if (element.getAttribute('data-name') === 'Facebook') {
          const userAgent = navigator.userAgent || navigator.vendor || window.opera;
          const n = element.href.lastIndexOf('/');
          const urlAppend = element.href.substring(n + 1);

          if (/android/i.test(userAgent)) {
            element.href = 'fb://page/' + urlAppend;
            return;
          }
          if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            element.href = 'fb://profile/' + urlAppend;
            return;
          }
        }
      });
    },
    tabIndex() {
      const element = document.querySelectorAll('.footer__menu-title');
      element.forEach(function(e) {
        if (window.innerWidth >= LG) {
          e.setAttribute('tabindex', '-1');
        } else {
          e.setAttribute('tabindex', '0');
        }
      });
    },
  },
};
</script>
