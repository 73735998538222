import { asyncApiData } from '~/utils/data';

export default async function asyncPageData(path, nuxtError, contentItem) {
  let dataOptions = {
    path,
  };

  // Either get page data directly by contentItem or fallback to pathMatch

  if (contentItem) {
    dataOptions = {
      contentItem,
    };
  }

  return asyncApiData({
    nuxtError,
    ...dataOptions,
  });
}
