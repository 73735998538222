<template>
  <div>
    <component
        :is="modules.BlogArticleHero" v-if="featuredImage" :image="featuredImage"
    />
    <component
        :is="modules.BlogArticle"
        v-if="date && author && title && body"
        v-bind="{ author, tag, date, title, body }"
    />
  </div>
</template>

<script>

import moduleLoader from '~/utils/module-loader';
import { blog_article_hero as BlogArticleHero, blog_article as BlogArticle } from '~/.modules.json';

// Set modules on page
const modules = [BlogArticleHero, BlogArticle];

export default {
  scrollToTop: true,
  props: {
    featuredImage: {
      type: Object,
      required: true,
      default() {
        return {};
      } },
    author: {
      type: Object,
      required: true,
      default() {
        return {};
      } },
    date: {
      type: String,
      required: true,
    },
    tag: {
      type: Object,
      default() {
        return {};
      } },
    body: {
      type: Array,
      required: true,
      default() {
        return [];
      } },

    title: { type: String, required: false, default: null },
    metaTitle: { type: String, required: false, default: null },
    metaDescription: { type: String, required: false, default: null },
  },
  beforeCreate() {
    this.modules = moduleLoader(modules);
  },
  mounted() {
    this.$scrollEntrance.mount();
  },
  beforeDestroy() {
    this.$scrollEntrance.destroy();
  },
};

</script>
