<template>
  <div :class="{ hideBeforeLoad: isNotLoaded, fadeAfterLoad: !isNotLoaded }">
    <no-ssr v-if="!cookieBannerAccepted"> <CookieBanner /> </no-ssr>
    <crisis-notification-bar :template="template" role="banner" />
    <main-header :template="template" role="banner" />
    <component :is="renderTemplate" v-if="renderTemplate" id="main-content" :class="{'offset-by-crisis-bar': crisisNotificationAlert && crisisNotificationAlert.body}" v-bind="data" role="main" />
    <ErrorModule v-else :message="notFoundError" :error="500" />
    <main-footer role="contentinfo" />
    <no-ssr>
      <Booking />
    </no-ssr>
  </div>
</template>

<script>
import pascalCase from 'pascal-case';
import { createNamespacedHelpers } from 'vuex';
import asyncPageData from '~/utils/page';

// Modules
import MainHeader from '~/components/modules/MainHeader';
import CrisisNotificationBar from '~/components/modules/CrisisNotificationBar';
import ErrorModule from '~/components/modules/ErrorModule';
import CookieBanner from '~/components/modules/CookieBanner';
import MainFooter from '~/components/modules/Footer';

// Booking
import Booking from '~/components/modules/Booking';

// Templates
import Homepage from '~/components/templates/Homepage';
import About from '~/components/templates/About';
import Location from '~/components/templates/Location';
import CoLiving from '~/components/templates/CoLiving';
import Blog from '~/components/templates/Blog';
import BlogPost from '~/components/templates/BlogPost';
import Faqs from '~/components/templates/Faqs';
import Careers from '~/components/templates/Careers';
import Cookies from '~/components/templates/Cookies';
import Terms from '~/components/templates/Terms';
import Privacy from '~/components/templates/Privacy';
import LandingPage from '~/components/templates/LandingPage';

import { NAMESPACE, UPDATE_LAST_PATH } from '~/store/sitewide';

const { mapActions, mapGetters } = createNamespacedHelpers(NAMESPACE);

export default {
  components: {
    MainHeader,
    CrisisNotificationBar,
    ErrorModule,
    CookieBanner,
    Homepage,
    LandingPage,
    CoLiving,
    About,
    Location,
    Blog,
    BlogPost,
    Faqs,
    Careers,
    Cookies,
    Terms,
    Privacy,
    Booking,
    MainFooter,
  },
  transition: {
    name: 'fade',
    duration: { enter: 750, leave: 750 },
  },
  data() {
    return {
      data: null,
      template: null,
      isNotLoaded: true,
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.UPDATE_LAST_PATH({
      path: this.$route.path,
    });

    next();
  },
  computed: {
    ...mapGetters(['metaDescription', 'companyName', 'cookieBannerAccepted', 'crisisNotificationAlert']),
    notFoundError() {
      return `${this.template} template not found`;
    },
    renderTemplate() {
      const templateName = pascalCase(this.template);

      if (this.$options.components[templateName]) {
        return templateName;
      }

      return null;
    },
    title() {
      if (this.data && this.data.title) {
        return this.data.title;
      }

      return '';
    },
  },
  async asyncData({ params, error, contentItem }) {
    return asyncPageData(params.pathMatch, error, contentItem);
  },
  mounted() {
    setTimeout(()=>(this.isNotLoaded = false), 500);

    if (process.browser) {
      window.onNuxtReady(() => {
        document.body.classList.add('js');

        // intercom script here
        // window.intercomSettings = {
        //   app_id: 'kaotu1oq',
        // };

        // const w = window;
        // const ic = w.Intercom;
        // if (typeof ic === 'function') {
        //   ic('reattach_activator');
        //   ic('update', w.intercomSettings);
        // } else {
        //   const d = document;
        //   const i = function() {
        //     // eslint-disable-next-line prefer-rest-params
        //     i.c(arguments);
        //   };
        //   i.q = [];
        //   i.c = function(args) {
        //     i.q.push(args);
        //   };
        //   w.Intercom = i;
        //   const l = function() {
        //     const s = d.createElement('script');
        //     s.type = 'text/javascript';
        //     s.async = true;
        //     s.src = 'https://widget.intercom.io/widget/kaotu1oq';
        //     s.defer = true;
        //     const x = d.getElementsByTagName('script')[0];
        //     x.parentNode.insertBefore(s, x);
        //   };
        //   if (w.attachEvent) {
        //     w.attachEvent('onload', l);
        //   } else {
        //     w.addEventListener('load', l, false);
        //   }
        // }
      });
    }
  },
  head() {

    const metaTitle = this.getMetaTitle();
    const metaDescription = this.getMetaDescription();
    const metaNoIndex = this.addMetaNoIndex() ? 'noindex' : '';

    return {
      title: metaTitle,
      script: [
        {
          src: 'https://www.mews.li/distributor/distributor.min.js',
          defer: true,
        },
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: metaDescription,
        },
        {
          hid: 'robots',
          name: 'robots',
          content: metaNoIndex,
        },
      ],
    };
  },
  methods: {
    ...mapActions([UPDATE_LAST_PATH]),
    getMetaTitle() {
      if (this.data && this.data.metaTitle) {
        return this.data.metaTitle;
      }

      if (this.title) {
        return this.getDefaultMetaTitle();
      }

      return `${this.companyName}`;
    },
    getDefaultMetaTitle() {
      return `${this.title} | ${this.companyName}`;
    },
    getMetaDescription() {
      if (this.data && this.data.metaDescription) {
        return this.data.metaDescription;
      }

      return this.metaDescription;
    },
    addMetaNoIndex() {
      if (this.data && this.data.noIndexMetaTag) {
        return typeof this.data.noIndexMetaTag.find((item) => item.codename === 'addNoIndexMetaTag') !== 'undefined';
      }
      return false;
    },
  },
};
</script>
