<template>
  <div v-click-outside="hide" class="input-select field-wrapper label-animate" @click="toggle">
    <input
        :id="`field-${id}`"
        :name="name"
        type="text"
        :value="input"
        :class="inputClass"
        readonly
        @blur="blurValidate"
        @focus="focus"
    >

    <label :for="`field-${id}`" class="animated-label">{{ label }}</label>

    <Chevron class="select-wrapper__icon" />

    <div v-if="active" class="input-select__dropdown">
      <ul>
        <li v-for="(item, index) in options" :key="index" @click="setItem(item.value)">{{ item.label }}</li>
      </ul>
    </div>

    <span v-if="error" class="error-message">{{ errorMessage }}</span>
  </div>
</template>

<script>

export default {
  props: {
    value: { type: String, required: false, default: null },
    name: { type: String, required: true },
    label: { type: String, required: true },
    options: { type: Array, required: true },
    isDirty: { type: Boolean, required: false },
    initial: { type: String, required: false, default: null },
    errorMessage: { type: String, default: null },
    focus: { type: Function, required: false, default: () => 1 },
  },
  data() {
    return {
      active: false,
      input: '',
      hasValue: false,
      id: null,
      error: false,
    };
  },
  computed: {
    inputClass() {
      return {
        'select-wrapper__select': true,
        'has-value': this.hasValue,
        'error': this.error,
      };
    },
    labelClass() {
      return {
        'select-wrapper__label': true,
        'select-wrapper__label--hidden': this.labelHidden,
      };
    },
  },

  watch: {
    isDirty(isDirty) {
      if (!isDirty) {
        this.reset();
      }
    },
  },
  mounted() {
    this.id = this._uid;
    this.$emit('input', '');
    this.initial && this.setItem(this.initial);
  },
  methods: {
    hide() {
      this.active = false;
    },
    toggle() {
      this.active = !this.active;
    },
    reset() {
      this.hasValue = false;
      this.input = null;
      this.$emit('input', null);
    },
    setItem(value) {
      this.input = value;

      this.hasValue = this.input && this.input.length > 0;
      this.$emit('input', this.input);
      this.blurValidate(); // we need to call this here otherwise the error state is not updated as the last blur comes from the <li> and not the <input> when you select an option.
    },
    blurValidate() {
      this.error = !this.hasValue;
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/elements/_input-select.scss';
</style>
