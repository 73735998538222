<template>
  <div>
    <component :is="modules.GenericHero" v-if="hero" v-bind="hero" />
    <component :is="modules.GenericVideo" v-if="videoId" :video-id="videoId" />
    <component :is="modules.AnchorLinks" v-if="anchorLinks" v-bind="anchorLinks" />
    <component :is="modules.TermsAndConditions" v-if="termsAndConditions && termsAndConditions.title" v-bind="termsAndConditions" />
    <component :is="modules.GenericContent" v-if="intro" v-bind="intro" />
    <component :is="modules.RoomsListing" v-if="roomsListing" v-bind="roomsListing" class="push-v--desktop--large push-v--tablet--large push-v--mobile--large" />
    <component :is="modules.RoomsPricing" v-if="roomsPricing" v-bind="roomsPricing" class="push-v--desktop--large push-v--tablet--large push-v--mobile--large" />
    <component :is="modules.Offers" v-if="offers" v-bind="offers" />
    <component :is="modules.OurValues" v-if="ourValues" v-bind="ourValues" />
    <component :is="modules.CoLivingBenefits" v-if="coLivingBenefits" v-bind="coLivingBenefits" />
    <component :is="modules.KeyWorkerEnquiry" v-if="keyWorkerEnquiry" v-bind="keyWorkerEnquiry" />
    <component :is="modules.SafetyCleanliness" v-if="safetyCleanliness" v-bind="safetyCleanliness" />
    <component :is="modules.FindUs" v-if="howToFindUs" v-bind="howToFindUs" />
    <component :is="modules.LocationMap" v-if="locationMap" v-bind="locationMap" />
    <component :is="modules.CoWorking" v-if="coWorking" v-bind="coWorking" />
    <component :is="modules.CurrentResidents" v-if="ctaCarousel" v-bind="ctaCarousel" />
    <component :is="modules.BlogHighlights" v-if="showRelatedBlogPosts" :items="relatedBlogPosts" />
    <component :is="modules.Faqs" v-if="faqs" :faqs="faqsArray" />
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex';
import moduleLoader from '~/utils/module-loader';
import { NAMESPACE, SET_CRISIS_NOTIFICATION_ALERT } from '~/store/sitewide';

import {
  generic_hero as GenericHero,
  anchor_links as AnchorLinks,
  rooms_listing as RoomsListing,
  rooms_pricing as RoomsPricing,
  our_values as OurValues,

  co_living_benefits as CoLivingBenefits,
  find_us as FindUs,
  location_map as LocationMap,
  generic_video as GenericVideo,
  generic_content as GenericContent,
  blog_highlights as BlogHighlights,
  co_working as CoWorking,
  key_worker_enquiry as KeyWorkerEnquiry,
  safety_cleanliness as SafetyCleanliness,
  cta_carousel as CurrentResidents,
  terms_and_conditions as TermsAndConditions,
  offers as Offers,
  faqs as Faqs,
} from '~/.modules.json';

// Set modules on page

const modules = [
  GenericHero,
  RoomsListing,
  RoomsPricing,
  AnchorLinks,
  OurValues,
  SafetyCleanliness,
  CoLivingBenefits,
  FindUs,
  LocationMap,
  GenericVideo,
  GenericContent,
  CoWorking,
  KeyWorkerEnquiry,
  BlogHighlights,
  CurrentResidents,
  TermsAndConditions,
  Offers,
  Faqs,
];

const { mapActions } = createNamespacedHelpers(NAMESPACE);

export default {
  props: {
    hero: { type: Object, default: null },
    anchorLinks: { type: Object, required: false, default: null },
    roomsListing: { type: Object, default: null },
    roomsPricing: { type: Object, default: null },
    ourValues: { type: Object, default: null },
    coLivingBenefits: { type: Object, default: null },
    howToFindUs: { type: Object, default: null },
    locationMap: { type: Object, default: null },
    termsAndConditions: { type: Object, required: false, default: null },
    intro: { type: Object, default: null },
    relatedBlogPosts: { type: Array, default: null },
    ctaCarousel: { type: Object, default: null },
    title: { type: String, required: false, default: null },
    coWorking: { type: Object, required: false, default: null },
    keyWorkerEnquiry: { type: Object, required: false, default: null },
    metaTitle: { type: String, required: false, default: null },
    metaDescription: { type: String, required: false, default: null },
    metaNoIndex: { type: String, required: false, default: null },
    videoId: { type: String, required: false, default: null },
    safetyCleanliness: { type: Object, default: null },
    offers: { type: Object, default: null },
    faqs: { type: Object, required: true, default: null },
    crisisNotificationAlert: { type: Object, required: false, default: null },
  },
  data: function() {
    return {
      faqsArray: null,
    };
  },
  computed: {
    showRelatedBlogPosts() {
      if (this.relatedBlogPosts && this.relatedBlogPosts.length > 0) {
        return true;
      }
      return false;
    },
  },
  beforeCreate() {
    this.modules = moduleLoader(modules);
  },
  created() {
    this.faqsArray = [this.faqs];
  },
  mounted() {
    this.$scrollEntrance.mount();
    this.SET_CRISIS_NOTIFICATION_ALERT({
      crisisNotificationAlert: this.crisisNotificationAlert,
    });
  },
  beforeDestroy() {
    this.$scrollEntrance.destroy();
  },
  methods: {
    ...mapActions([SET_CRISIS_NOTIFICATION_ALERT]),
  },
};
</script>

