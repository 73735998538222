import pascalCase from 'pascal-case';
import asyncComponent from '~/async-component';

export default function moduleLoader(loadModules) {
  const modules = {};

  loadModules.forEach(({ key }) => {
    modules[pascalCase(key)] = () => asyncComponent(key);
  });

  return modules;
}
