<template>
  <div>
    <component :is="modules.GenericHero" v-if="genericHero" v-bind="genericHero" />
    <component :is="modules.GenericContent" v-if="genericContent" v-bind="genericContent" />
    <component :is="modules.JobListing" v-if="jobListing" />
    <component :is="modules.Cta" v-if="cta" v-bind="cta" />
  </div>
</template>

<script>
import moduleLoader from '~/utils/module-loader';

import {
  generic_hero as GenericHero,
  generic_content as GenericContent,
  cta as Cta,
  job_listing as JobListing,
} from '~/.modules.json';

// Set modules on page

const modules = [GenericHero, GenericContent, Cta, JobListing];

export default {
  props: {
    genericHero: { type: Object, required: true, default: null },
    genericContent: { type: Object, required: true, default: null },
    cta: { type: Object, required: true, default: null },
    jobListing: { type: Object, required: true, default: null },

    title: { type: String, required: false, default: null },
    metaTitle: { type: String, required: false, default: null },
    metaDescription: { type: String, required: false, default: null },
  },
  beforeCreate() {
    this.modules = moduleLoader(modules);
  },
  mounted() {
    this.$scrollEntrance.mount();
  },
  beforeDestroy() {
    this.$scrollEntrance.destroy();
  },
};
</script>
