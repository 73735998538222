<template>
  <section class="newsletter-signup">
    <div class="container">
      <div v-if="!success" class="row">
        <div class="text-center--mobile text-center--tablet col-10 offset-1 col-lg-3 offset-lg-0">
          <h2 class="newsletter-signup__title h5" v-html="newsletterTitle" />
        </div>
        <div class="col-10 offset-1 col-lg-9 offset-lg-0 col-xl-8">
          <form
              ref="form"
              action="generatedAction"
              class="newsletter-signup__form"
              method="post"
              @submit.prevent="submit"
          >
            <input-text
                ref="email"
                v-model="email"
                :label="emailFieldPlaceholder"
                autocomplete-value="email"
                error-message="Please enter your email address in format: yourname@example.com"
                validate="required|email"
                class="newsletter-signup__email push-v--mobile--small push-v--tablet--small"
                name="MERGE0"
                type="email"
                @input="validate"
            />
            <div class="text-center--mobile text-center--tablet">
              <button :disabled="isDisabled" class="newsletter-signup__button btn--dark-green" type="submit">
                {{ !loading ? submitButtonText : '' }} <loading v-if="loading" />
                <Arrow class="btn--arrow__icon btn--arrow__icon--white" />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div v-if="success" class="row justify-content-center">
        <div class="text-center--mobile text-center--tablet col-12">
          <h2 class="newsletter-signup__title h5 text-center">Thank you for signing up.</h2>
        </div>
      </div>
    </div>
    <CurvedBlueShape class="newsletter-signup__background-shape" />
  </section>
</template>

<script>
import InputText from '~/components/elements/InputText';
import CurvedBlueShape from '~/assets/images/footer/curved-blue-shape.svg?inline';
import { trackNewsletter } from '~/utils/gtm-events.js';
import Cookie from 'js-cookie';
import Loading from './Loading';

export default {
  components: {
    InputText,
    CurvedBlueShape,
    Loading,
  },

  props: {
    newsletterTitle: {
      type: String,
      default: 'Get in touch',
    },

    userId: {
      type: String,
      required: true,
    },

    listId: {
      type: String,
      required: true,
    },

    emailFieldPlaceholder: {
      type: String,
      default: 'Your email address',
    },

    submitButtonText: {
      type: String,
      default: 'Submit',
    },

    portalId: {
      type: String,
      required: true,
    },

    formId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      action: 'https://api.hsforms.com/submissions/v3/integration/submit',
      email: '',
      isDisabled: true,
      loading: false,
      success: false,
    };
  },
  computed: {
   

  },
  methods: {
    validate() {
      if (this.$refs.email.isValid()) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    async submit() {
      if (this.$refs.email.isValid()) {
        this.loading = true;
        const data = {
          'submittedAt': new Date().getTime(),
          'fields': [
            {
              'name': 'email',
              'value': this.email,
            },
          ],
          'context': {
            'hutk': Cookie.get('hubspotutk'), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
            'pageUri': window.location.href,
            'pageName': window.document.title,
          },
        };
        const res = await fetch(`${this.action}/${this.portalId}/${this.formId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        const response = await res.json();
        this.loading = false;
        if (response && !response.error) {
          this.success = true;
        }


      }
      trackNewsletter(this.$gtm, this.$refs.email.value);
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/components/_newsletter-signup.scss';
</style>
