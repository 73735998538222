<template>
  <div>
    <component :is="modules.HomepageHero" v-if="homepageHero" v-bind="homepageHero" />
    <component :is="modules.GenericHero" v-if="genericHero" v-bind="genericHero" />
    <component :is="modules.CoLivingHighlights" v-if="coLivingHighlights" v-bind="coLivingHighlights" />
    <component :is="modules.LocationLookAround" v-if="lookAround" v-bind="{...lookAround,isForHome:true}" />
    <component :is="modules.BenefitsListing" v-if="benefitsListing" v-bind="benefitsListing" />
    <component :is="modules.GenericContent" v-if="genericContent" v-bind="genericContent" />
    <component :is="modules.RoomsListing" v-if="roomsListing" v-bind="roomsListing" />
    <component :is="modules.RoomsPricing" v-if="roomsPricing" v-bind="roomsPricing" />
    <component :is="modules.CoWorkingListing" v-if="coWorkingListing" v-bind="coWorkingListing" />
    <component :is="modules.CollectiveCommunity" v-if="collectiveCommunity" v-bind="collectiveCommunity" />
    <component :is="modules.LocationListing" v-if="locations" v-bind="locations" />
    <component :is="modules.SafetyCleanliness" v-if="safetyCleanliness" v-bind="safetyCleanliness" />
    <component :is="modules.CurrentResidents" v-if="ctaCarousel" v-bind="ctaCarousel" class="current-residents--push-top" />
    <component :is="modules.CoLivingBenefits" v-if="coLivingBenefits" v-bind="{...coLivingBenefits,forHome:true}" />
    <component :is="modules.CoWorking" v-if="coWorking" v-bind="coWorking" />
    <component :is="modules.EventsListing" v-if="eventsListing" v-bind="eventsListing" />
    <component :is="modules.Testimonials" v-if="testimonials" v-bind="testimonials" />
    <component :is="modules.BlogHighlights" v-if="blogHighlights" :items="blogLatest" v-bind="blogHighlights" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import moduleLoader from '~/utils/module-loader';
import { GetLatestPosts } from '~/utils/blog';

import { NAMESPACE, SET_CRISIS_NOTIFICATION_ALERT } from '~/store/sitewide';

import {
  cookie_banner as CookieBanner,
  homepage_hero as HomepageHero,
  generic_hero as GenericHero,
  location_look_around as LocationLookAround,
  co_living_benefits as CoLivingBenefits,
  co_working as CoWorking,
  co_living_highlights as CoLivingHighlights,
  location_listing as LocationListing,
  events_listing as EventsListing,
  collective_community as CollectiveCommunity,
  current_residents as CurrentResidents,
  testimonials as Testimonials,
  rooms_listing as RoomsListing,
  rooms_pricing as RoomsPricing,
  co_working_listing as CoWorkingListing,
  generic_content as GenericContent,
  benefits_listing as BenefitsListing,
  blog_highlights as BlogHighlights,
  safety_cleanliness as SafetyCleanliness,
} from '~/.modules.json';

// Set modules on page

const modules = [
  CookieBanner,
  HomepageHero,
  CoLivingHighlights,
  LocationListing,
  EventsListing,
  CoLivingBenefits,
  CoWorking,
  CollectiveCommunity,
  CurrentResidents,
  Testimonials,
  RoomsListing,
  RoomsPricing,
  CoWorkingListing,
  GenericContent,
  BenefitsListing,
  GenericHero,
  BlogHighlights,
  SafetyCleanliness,
  LocationLookAround,
];

const { mapActions } = createNamespacedHelpers(NAMESPACE);

export default {
  props: {
    homepageHero: { type: Object, default: null },
    genericHero: { type: Object, default: null },
    lookAround: { type: Object, required: false, default: null },
    coLivingHighlights: { type: Object, required: true, default: null },
    collectiveCommunity: { type: Object, default: null },
    locations: { type: Object, default: null },
    currentResidents: { type: Object, default: null },
    testimonials: { type: Object, default: null },
    eventsListing: { type: Object, default: null },
    coLivingBenefits: { type: Object, default: null },
    coWorking: { type: Object, required: false, default: null },
    genericContent: { type: Object, default: null },
    roomsListing: { type: Object, default: null },
    roomsPricing: { type: Object, default: null },
    coWorkingListing: { type: Array, default: null },
    benefitsListing: { type: Object, default: null },
    blogHighlights: { type: Object, default: null },
    ctaCarousel: { type: Object, default: null },
    safetyCleanliness: { type: Object, default: null },
    title: { type: String, required: false, default: null },
    metaTitle: { type: String, required: false, default: null },
    metaDescription: { type: String, required: false, default: null },
    crisisNotificationAlert: { type: Object, required: false, default: null },    
  },
  data() {
    return {
      blogLatest: [],
    };
  },
  beforeCreate() {
    this.modules = moduleLoader(modules);
  },
  mounted() {
    console.log('Hello, World!');
    this.$scrollEntrance.mount();
    this.getBlogHighlight();
    this.SET_CRISIS_NOTIFICATION_ALERT({
      crisisNotificationAlert: this.crisisNotificationAlert,
    });
  },
  beforeDestroy() {
    this.$scrollEntrance.destroy();
  },
  methods: {
    async getBlogHighlight() {
      const posts = await GetLatestPosts();
      this.blogLatest = posts.data;
    },
    ...mapActions([SET_CRISIS_NOTIFICATION_ALERT]),
  },
};
</script>
