<template>
  <component :is="modules.CookiePolicy" v-if="cookiePolicy" v-bind="cookiePolicy" />
</template>

<script>
import moduleLoader from '~/utils/module-loader';
import { cookie_policy as CookiePolicy } from '~/.modules.json';

// Set modules on page

const modules = [CookiePolicy];

export default {
  props: {
    cookiePolicy: { type: Object, required: true },

    title: { type: String, required: false, default: null },
    metaTitle: { type: String, required: false, default: null },
    metaDescription: { type: String, required: false, default: null },
  },
  beforeCreate() {
    this.modules = moduleLoader(modules);
  },
  mounted() {
    this.$scrollEntrance.mount();
  },
  beforeDestroy() {
    this.$scrollEntrance.destroy();
  },
};
</script>
