import pascalCase from 'pascal-case';

export default function asyncComponent(file, params = {}) {
  const filename = pascalCase(file);

  return {
    component: import(`./components/modules/${filename}`),
    ...params,
  };
}
