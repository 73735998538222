<template>
  <div>
    <component :is="modules.GenericHero" v-if="genericHero" v-bind="genericHero" />
    <component :is="modules.GenericContent" v-if="what" v-bind="what" />
    <component :is="modules.CoLivingBenefits" v-if="benefits" v-bind="benefits" />
    <component :is="modules.LocationListing" v-if="locations" v-bind="locations" />
    <component :is="modules.CurrentResidents" v-if="currentResidents" v-bind="currentResidents" />
    <component :is="modules.GenericContent" v-if="about" v-bind="about" />
    <component :is="modules.GenericContent" v-if="learnDevelopGrow" v-bind="learnDevelopGrow" />
    <component :is="modules.Faqs" v-if="faqs" :faqs="faqsArray" />
  </div>
</template>

<script>
import moduleLoader from '~/utils/module-loader';

import {
  generic_hero as GenericHero,
  generic_content as GenericContent,
  co_living_benefits as CoLivingBenefits,
  location_listing as LocationListing,
  current_residents as CurrentResidents,
  faqs as Faqs, 
} from '~/.modules.json';

const modules = [GenericHero, GenericContent, CoLivingBenefits, LocationListing, CurrentResidents, Faqs];

export default {
  props: {
    genericHero: { type: Object, required: true, default: null },
    what: { type: Object, default: null },
    benefits: { type: Object, default: null },
    locations: { type: Object, default: null },
    currentResidents: { type: Object, default: null },
    about: { type: Object, default: null },
    learnDevelopGrow: { type: Object, default: null },
    faq: { type: Object, default: null },
  },
  data: function() {
    return {
      faqsArray: null,
    };
  },
  beforeCreate() {
    this.modules = moduleLoader(modules);
  },
  created() {
    this.faqsArray = [this.faqs];
  },
  mounted() {
    this.$scrollEntrance.mount();
  },
  beforeDestroy() {
    this.$scrollEntrance.destroy();
  },
};
</script>
