<template>
  <div @keydown.tab.shift.exact.prevent="moveFocusBack()" @keydown.tab.exact.prevent="moveFocusForward()">
    <modal
        id="booking"
        :pivot-x="1"
        :width="356"
        height="100%"
        classes="booking-sidebar"
        aria-modal="true"
        name="booking"
        transition="fade"
        :delay="225"
        @before-open="beforeOpen"
        @before-close="beforeClose"
        @opened="opened"
    >
      <div slot="top-right">
        <button v-show="showing" class="booking-close focusable" :aria-label="'Close Booking Options Menu'" @click="closed"><Cross /></button>
      </div>

      <div v-if="!form.isSubmitted" class="booking-nav">
        <!-- <div :class="{'active':bookingType===TABNAME_SHORT_STAY}" class="booking-nav-item" @click="selectShortStay(true)">
          Stay for a few nights
        </div> -->
        <div :class="{'active':bookingType===TABNAME_LONG_STAY}" class="booking-nav-item" @click="selectLongStay(true)">
          Move in for months
        </div>
      </div>

      <div v-if="!form.isSubmitted" class="booking-step-1">
        <div class="booking-info">
          <div v-if="bookingType==TABNAME_LONG_STAY">Become a Collective member. This is flexible living as it should be: a furnished private apartment, incredible shared spaces, daily events, great people and everything included in one easy bill</div>
          <div v-if="bookingType==TABNAME_SHORT_STAY">Get a taste of The Collective experience. You’ll get a studio apartment, one-of-a-kind shared social and work spaces, and access to our signature events programme.</div>
          <br><br>
          * Fields required
        </div>


        <div>
          <div v-if="bookingType==TABNAME_LONG_STAY" class="booking-sidebar__step1--details">
            <form
                id="hsForm_35b39485-6b4a-4738-9c1e-616ce79cd058"
                ref="longStayHubspotForm"
                method="POST"
                accept-charset="UTF-8"
                enctype="multipart/form-data"
                class="field--dark"
                novalidate=""
                :action="formActionLink"
                target="target_iframe_35b39485-6b4a-4738-9c1e-616ce79cd058"
                data-instance-id="c192db61-6bc1-432e-8df2-b8cce831240c"
                :data-form-id="hubspotFormId"
                :data-portal-id="hubspotPortalId"
            >
              <div v-once id="hubspotForm" />
              <!-- FIRST NAME -->
              <div>
                <input-text                   
                    :id="`firstname-${hubspotFormId}`"
                    ref="firstname"
                    v-model="values.fname"
                    name="firstname"
                    label="* First Name"
                    autocomplete-value="given-name"
                    error-message="Please enter your first name"
                    validate="required"
                    type="text"
                    :focus="triggerGTMFocus"
                    :change="handleInputChange"
                    @input="validate"
                />
              </div>

              <!-- LAST NAME -->
              <div>
                <input-text                   
                    :id="`lastname-${hubspotFormId}`"
                    ref="lastname"
                    v-model="values.lname"
                    name="lastname"
                    label="* Last Name"
                    autocomplete-value="family-name"
                    error-message="Please enter your last name"
                    validate="required"
                    type="text"
                    :focus="triggerGTMFocus"
                    :change="handleInputChange"
                    @input="validate"
                />
              </div>

              <!-- PHONE NUMBER -->
              <div>
                <input-text
                    :id="`phone-${hubspotFormId}`"
                    ref="phone"
                    v-model="values.phone"
                    name="phone"
                    label="* Contact Number"
                    autocomplete-value="tel"
                    error-message="Please enter a valid phone number."
                    :validate="{ required: true, regex: /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/ }"
                    type="tel"
                    :focus="triggerGTMFocus"
                    :change="handleInputChange"
                    @input="validate"
                />
              </div>

              <!-- EMAIL -->
              <div>
                <input-text
                    :id="`email-${hubspotFormId}`"
                    ref="email"
                    v-model="values.email"
                    name="email"
                    label="* Email Address"
                    autocomplete-value="email"
                    error-message="Please enter a valid email address."
                    :validate="{ required: true, regex: emailPattern }"
                    type="email"
                    :focus="triggerGTMFocus"
                    :change="handleInputChange"
                    @input="validate"
                />
              </div>

              <!-- BUDGET -->
              <div>
                <input-text
                    :id="`budget-${hubspotFormId}`"
                    ref="budget"
                    v-model="values.budget"
                    name="budget"
                    label="* Budget"
                    error-message="Please enter a value."
                    :validate="{ required: true }"
                    type="number"
                    :focus="triggerGTMFocus"
                    :change="handleInputChange"
                    @input="validate"
                />
              </div>

              <!-- LOCATION -->
              <input-select
                  :id="`location_of_interest-${hubspotFormId}`"
                  ref="location"
                  v-model="values.location"
                  name="location"
                  class="location-field"
                  :initial="location"
                  :is-dirty="form.isDirty"
                  :options="locationsForStay.map(location=>({
                    id: location.link.contentCodename,
                    label: location.title,
                    value: location.title,
                  }))"
                  :searchable="false"
                  :clearable="false"
                  label="* Location"
                  error-message="Please pick a Location"
                  validate="required"
                  @input="changeLocation"
              />

              <!-- MOVE IN DATE -->
              <div class="input-icon-wrapper">
                <input-date
                    :id="`preferred_move_in_date_keep_-${hubspotFormId}`"
                    ref="moveinDate"
                    v-model="values.moveinDate"
                    v-visible="values.location!==LOCATION_PAPER_FACTORY"
                    name="preferred_move_in_date_keep_"
                    :label="bookingType==TABNAME_LONG_STAY ? 'Move in': 'Arrival Date / Departure Date'"
                    :disabled-dates="form.disabledDatesStart"
                    :focus="triggerGTMFocus"
                    :allow-range="bookingType==TABNAME_SHORT_STAY ? true : false"
                    :change="handleInputChange"
                    @input="changeDate"
                />
              </div>

              <!-- GDPR OPT IN -->
              <!-- <div class="booking-info">
                <div>The Collective is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:</div>
                <input-check
                    ref="confirmed"
                    v-model="confirmed"
                    label="I agree to receive other communications from The Collective."
                    class="gdpr-interest__field"
                    name="CONFIRMED"
                    @input="validate"
                />
                <div>You may unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.</div>
              </div> -->

              <div class="booking-sidebar__button" :class="{'tour-form__button':bookingType==='tour'}">
                <button
                    :disabled="form.submitDisabled"
                    class="btn--dark-green submit"
                    type="submit"
                    :change="handleInputChange"
                    @click.prevent="submit"
                >
                  Request Information
                </button>

                <button
                    v-if="shouldShowBookNowButton"
                    type="button"
                    class="btn--pale-green submit"
                    :disabled="form.submitDisabled"
                    @click="openExternalBookingForm"
                >
                  Book Now
                </button>
              </div>
            </form>
          </div>

          <div v-else-if="bookingType==TABNAME_SHORT_STAY" class="booking-sidebar__step1--details">
            <form class="field--dark" method="post" role="form">
              <!-- LOCATION / ALL-->
              <input-select
                  id="v-select"
                  ref="location"
                  v-model="values.location"
                  class="location-field"
                  :initial="location"
                  :is-dirty="form.isDirty"
                  :options="locationsForStay.map(location=>({
                    id: location.link.contentCodename,
                    label: location.title,
                    value: location.title,
                  }))"
                  :searchable="false"
                  :clearable="false"
                  label="* Location"
                  error-message="Please pick a Location"
                  validate="required"
                  name="LOCATION"
                  @input="changeLocation"
              />

              <!-- MOVE IN DATE / ALL -->
              <div class="input-icon-wrapper">
                <input-date
                    ref="moveinDate"
                    v-model="values.moveinDate"
                    v-visible="values.location!==LOCATION_PAPER_FACTORY"
                    :label="bookingType==TABNAME_LONG_STAY ? 'Move in': 'Arrival Date / Departure Date'"
                    name="MOVEINDATE"
                    :disabled-dates="form.disabledDatesStart"
                    :focus="triggerGTMFocus"
                    :allow-range="bookingType==TABNAME_SHORT_STAY ? true : false"
                    :change="handleInputChange"
                    @input="changeDate"
                />
              </div>

              <!-- VOUCHER / SHORT -->
              <input-text
                  ref="voucher"
                  v-model="values.voucher"
                  v-visible="values.location!==LOCATION_PAPER_FACTORY"
                  label="Got a Promotional Code?"
                  autocomplete-value="voucher"
                  error-message="Please enter a valid promotion code"
                  name="VOUCHER"
                  type="text"
                  :focus="triggerGTMFocus"
                  :blur="triggerGTMBlur"
                  :change="handleInputChange"
                  @input="validate"
              />

              <div class="booking-sidebar__button" :class="{'tour-form__button':bookingType==='tour'}">
                <button
                    :disabled="form.submitDisabled"
                    class="btn--dark-green submit"
                    type="submit"
                    :change="handleInputChange"
                    @click.prevent="submit"
                >
                  Select your room
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div v-if="!!form.isSubmitted && bookingType == TABNAME_LONG_STAY" class="booking-step-2">
        <img class="booking-sidebar__building-img" src="~/assets/images/sidebar/building-inner.png" :width="309">
        <div class="booking-info">
          Thanks for enquiring about The Collective <span class="booking-sidebar__location">{{ values.location }}</span>, we’re looking forward to showing you around. We’ll be in touch soon.
          <br><br>

          <!-- <a class="booking-sidebar__cta btn--white booking-sidebar__cta-icon" :aria-label="'Schedule a call with our team'" :href="hubspotMeetingURL" @click="clickMeetingCta">
            <div class="booking-sidebar__icon"><img src="~/assets/icons/calendar.svg" :width="45" :height="45"></div>
            <div class="booking-sidebar__cta-copy">Schedule a call with our team </div>
            <img src="~/assets/icons/chevron.svg" :width="16" :height="16">
          </a> -->
          <a class="booking-sidebar__cta btn--white booking-sidebar__cta-icon" :aria-label="'Take our 360 tour'" href="https://oldoak360tour.thecollective.com" @click="clic360Tour">
            <div class="booking-sidebar__icon"><img src="~/assets/images/sidebar/360tour.png"></div>
            <div class="booking-sidebar__cta-copy">Take our 360 tour </div>
            <img src="~/assets/icons/chevron.svg" :width="16" :height="16">
          </a>
        </div>
      </div>
    </modal>

    <!-- Start of HubSpot Embed Code -->
    <script id="hs-script-loader" type="text/javascript" async defer src="//js.hs-scripts.com/4016181.js" />
    <!-- End of HubSpot Embed Code -->
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import Moment from 'moment';
import { setTimeout } from 'timers';
import InputText from '~/components/elements/InputText';
import InputDate from '~/components/elements/InputDate';
import InputSelect from '~/components/elements/InputSelect';
import { focusElements } from '~/components/mixins/focus-elements.js';
import { getBookingStayPath } from '~/utils/booking';
import { TABNAME_LONG_STAY, TABNAME_SHORT_STAY, LOCATION_PAPER_FACTORY } from '~/utils/constants';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('sitewide');
import { clickedCta, trackClickedCta, STAY_SHORT, trackStay, trackEnquiry } from '~/utils/gtm-events.js';
import Cross from '~/assets/icons/cross.svg?inline';
import debounce from 'lodash.debounce';

const util = require('util');

export default {
  components: {
    InputText,
    InputDate,
    InputSelect,
    Cross,
  },
  mixins: [focusElements],
  data() {
    return {
      hubspot: {
        cw: {
          formId: process.env.HUBSPOT_FORM_ID_CW,
          portalId: '139632336',
        },
        oo: {
          formId: process.env.HUBSPOT_FORM_ID_OO,
          portalId: '25596573',
        },
        actionLink: 'https://api.hsforms.com/submissions/v3/integration/submit',
      },
      hubspotFormId: process.env.HUBSPOT_FORM_ID,
      // hubspotActionLink: 'https://api.hsforms.com/submissions/v3/integration/secure/submit/4016181/',
      // hubspotActionLink: 'https://forms.hsforms.com/submissions/v3/public/submit/formsnext/multipart/4016181/',
      hubspotPortalId: '139632336',
      hubspotMeetingURL: '',
      tourURL: '',
      showing: false,
      activeFilter: '',
      selectedLongStayLength: '',
      TABNAME_SHORT_STAY: TABNAME_SHORT_STAY,
      TABNAME_LONG_STAY: TABNAME_LONG_STAY,
      LOCATION_PAPER_FACTORY: LOCATION_PAPER_FACTORY,
      bookingType: TABNAME_LONG_STAY,
      tourType: '',
      location: '',
      moveinStartDate: '',
      moveinEndDate: '',
      locationsForStay: [],
      shortStayBookings: [],
      longStayBookings: [],
      currentSection: [],
      emailPattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        keyboard: {
          enabled: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          slideChange: () => {
            this.rooms.forEach((item, index) => {
              this.currentSection[index] = this[`sliderInstance${index}`]
                ? this[`sliderInstance${index}`].realIndex + 1
                : 1;
            });
            this.$forceUpdate(); // otherwise the progress-dots dont update
          },
        },
      },
      // Form values, which will be sent to formkeeper
      initialValues: {
        referrer: '',
        location: null,
        voucher: null,
        name: null,
        fname: null,
        lname: null,
        phone: null,
        email: null,
        monthlybudget: null,
        selectedLongStayLength: null,
        selectedRoom: null,
        selectedContactTime: null,
        selectedContactMethod: null,
        bookingType: null,
        tourType: null,
        moveinDate: null,
        created_at: new Date(),
      },
      values: { ...this.initialValues },
      // Form state
      form: {
        disabledDatesStart: {
          to: new Date(Date.now() - 86400000),
          dates: [new Date(Date.now() - 86400000)],
        },
        submitDisabled: true,
        isSubmitted: false,
        times: [],
        contactMethods: [
          {
            label: 'Email',
            value: 'Email',
          },
          {
            label: 'Phone',
            value: 'Phone',
          },
        ],
      },
      // NOTE: UNCOMMENT FOR RESHARMONICS
      // resharmonicsMigrated: {
      //   shortStay: true,
      //   longStay: false,
      // },
    };
  },
  computed: {
    ...mapGetters([
      'bookingLocations',
      'bookingCtaLongStay',
      'bookingCtaExtraLongStay',
      'bookingCtaShortStay',
    ]),
    formActionLink() {
      const { location } = this.values;
      const { actionLink, oo, cw } = this.hubspot;

      const { portalId, formId } = (location == 'Old Oak') ? oo : cw;

      return `${actionLink}/${portalId}/${formId}`;
    },
    longStayValues() {
      return {
        firstname: this.values.fname,
        lastname: this.values.lname,
        phone: this.values.phone,
        email: this.values.email,
        location: this.values.location,
        move_date: this.moveinStartDate,
        budget: this.values.budget,
      };
    },

    shouldShowBookNowButton() {
      return this.values && this.values.location === 'Canary Wharf';
    },
  },

  mounted() {
    console.log('51.2');
    this.values = { ...this.initialValues };
    if (window.location.pathname.includes('/thank-you-')) {      
      this.$modal.show('booking');
      this.changeBookingType(TABNAME_LONG_STAY);
    }
    if (window.location.pathname == '/book') {
      this.$modal.show('booking');
    }
    this.debouncedValidate = debounce(this.validate, 100);

    const script = document.createElement('script');
    script.src='https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
  },
  methods: {
    changeBookingType(selection) {
      this.bookingType=selection;
    },
    changeLocation(selectedLocation) {
      const eventData = {
        category: this.bookingType === TABNAME_SHORT_STAY ? 'ShortStayBooking' : 'LongStayBooking',
        action: 'ChooseLocation',
        label: 'Location',
        property: selectedLocation,
        value: null,
        fllocation: selectedLocation,
      };
      trackClickedCta(this.$gtm, eventData);
      this.validate();
    },
    changeDate(selectedDate) {
      const dates = selectedDate.split('-');
      const startDate = dates[0] && dates[0].trim();
      const endDate = dates[1] && dates[1].trim();

      this.moveinStartDate = Moment(startDate, 'Do MMM Y').format('YYYY-MM-DD');
      this.moveinEndDate = Moment(endDate, 'Do MMM Y').format('YYYY-MM-DD');

      const trackMoveinStartDate = Moment(startDate, 'Do MMM Y').format('YYYY-MM-DD');
      const trackMoveinEndDate = Moment(endDate, 'Do MMM Y').format('YYYY-MM-DD');

      if (this.bookingType === TABNAME_SHORT_STAY) {
        const eventDataMoveIn = {
          category: 'ShortStayBooking',
          action: 'ChooseDate',
          label: 'MoveInDate',
          property: trackMoveinStartDate,
          value: null,
        };
        trackClickedCta(this.$gtm, eventDataMoveIn);

        if (trackMoveinEndDate) {
          const eventDataMoveOut = {
            category: 'ShortStayBooking',
            action: 'ChooseDate',
            label: 'MoveOutDate',
            property: trackMoveinEndDate,
            value: null,
          };
          trackClickedCta(this.$gtm, eventDataMoveOut);
        }
      } else {
        const eventData = {
          category: 'LongStayBooking',
          action: 'ChooseDate',
          label: 'MoveInDate',
          value: trackMoveinStartDate,
          property: null,          
        };
        trackClickedCta(this.$gtm, eventData);
      }

      // if (this.$refs.moveinDate.selectedDate) {
      //   const disable = new Date(this.$refs.moveinDate.selectedDate);
      //   disable.setHours(1);
      // }
      this.validate();
    },
    clic360Tour() {
      const eventData = {
        category: 'LongStayBooking',
        action: 'Click',
        label: 'Take360Tour',
        value: null,
        property: null,
        event: 'clickedCta',
      };
      trackClickedCta(this.$gtm, eventData);
    },
    clickMeetingCta() {
      const eventData = {
        category: 'LongStayBooking',
        action: 'Click',
        label: 'ScheduleCall',
        value: null,
        property: null,
        event: 'clickedCta',
      };
      trackClickedCta(this.$gtm, eventData);
    },
    triggerGTMBlur(e) {
      if (e.target.name === 'VOUCHER') {
        const eventData = {
          category: 'ShortStayBooking',
          action: 'EnterText',
          label: 'PromoCode',
          value: e.target.value.toUpperCase(),
          property: null,
        };
        trackClickedCta(this.$gtm, eventData);
      }
    },
    debouncedValidate(e) {
    },
    handleInputChange(e) {
      const { name, value } = e.target;
      this.values[name.toLowerCase()] = value;
      this.debouncedValidate(e);
    },
    triggerGTMFocus(e) {
      let inputName = '';
      switch (e.target.name) {
        case 'firstname': {inputName = 'FirstName'; break;}
        case 'lastname': {inputName = 'LastName'; break;}
        case 'name': case 'NAME': {inputName = 'Name'; break;}
        case 'phone': case 'PHONE': {inputName = 'Phone'; break;}
        case 'email': case 'EMAIL': {inputName = 'Email'; break;}
        case 'VOUCHER': {inputName = 'Voucher'; break;}
        case 'preferred_move_in_date_keep_': case 'MOVEINDATE': {inputName = 'MoveInDate'; break;}
        default: {inputName = 'Error'; break;}
      }

      const eventData = {
        category: this.bookingType === TABNAME_SHORT_STAY ? 'ShortStayBooking' : 'LongStayBooking',
        action: 'EnterText',
        label: inputName,
        value: null,
        property: null,
      };
      trackClickedCta(this.$gtm, eventData);
    },
    enabled(location, type) {
      return location.link.bookingOptions && location.link.bookingOptions.enabled.filter(function(ele) {
        return (ele.codename == type);
      }).length > 0;
    },
    selectShortStay(clicked) {
      if (clicked) {
        const eventData = {
          category: 'SelectStayType',
          action: 'SelectShortStay',
          label: 'BookForAFewNights',
          value: null,
          property: 'SideBar',
        };
        trackClickedCta(this.$gtm, eventData);
        
        this.reset();
        this.$modal.hide('date-select-MOVEINDATE');
      }
      this.changeBookingType(TABNAME_SHORT_STAY);
      this.locationsForStay=this.shortStayBookings;
    },
    selectLongStay(clicked) {
      if (clicked) {
        const eventData = {
          category: 'SelectStayType',
          action: 'SelectLongStay',
          label: 'StayForMonths',
          value: null,
          property: 'SideBar',
        };
        trackClickedCta(this.$gtm, eventData);
        
        this.reset();
        this.$modal.hide('date-select-MOVEINDATE');
      }
      this.changeBookingType(TABNAME_LONG_STAY);
      this.locationsForStay=this.longStayBookings;
    },

    openBookingLongStay(location) {
      this.clearScrollLock();
      this.closed();

      if (location.link.bookingOptions.bookingUrl) {
        window.open(location.link.bookingOptions.bookingUrl);
      } else {
        this.$router.push({ path: getBookingStayPath(location.link.slug) });
      }
      
      // clickedCta(
      //     this.$gtm,
      //     `Book to long stay at ${location}`,
      //     location,
      //     null,
      //     null
      // );
    },
    openBookingShortStay(location) {
      trackStay(this.$gtm, location.title, STAY_SHORT);

      // NOTE: UNCOMMENT FOR RESHARMONICS
      // if (this.resharmonicsMigrated.shortStay) {
      //   this.openBookingModule(location);
      //   return;
      // }

      const _me = this;

      // Get mews distributor
      Mews.Distributor({
        configurationIds: [this.getDistributorId(location)],
      }, (distributor) => {

        const voucher = this.values.voucher ? String(this.values.voucher) : null;
        _me.closed();
        distributor.open();
        // distributor.setLanguageCode(languageCode);
        // distributor.setCurrencyCode(currencyCode);
        
        distributor.setStartDate(new Date(this.moveinStartDate));
        distributor.setEndDate(new Date(this.moveinEndDate));

        !!voucher && distributor.setVoucherCode(voucher);
        // distributor.setRooms(rooms);
        // Singlehotel mode api calls
        distributor.showRooms();
        // distributor.showRates(roomId);
        // Multihotel mode api calls
        // distributor.showHotels();
        // distributor.showRooms(hotelId);
        // distributor.setCity(cityId);
      },
      { dataBaseUrl: process.env.DEPLOY_STAGE.toLowerCase() === 'prod' ? process.env.MEWS_API_URL : process.env.MEWS_DEMO_API_URL }
      );

      clickedCta(
          this.$gtm,
          `Book to short stay at ${location}`,
          location,
          null,
          null
      );
    },
    // NOTE: UNCOMMENT FOR RESHARMONICS
    // openBookingModule(location) {
    //   const locationId = {
    //     'All locations': 0,
    //     'Old Oak': 5,
    //     'Canary Wharf (LS)': 6,
    //     'Canary Wharf': 7,
    //   };

    //   const adults = 1;
    //   const children = 0;
    //   const guests = adults + children;
    //   const buildingId = locationId[this.values.location] || 0;

    //   const baseURL = process.env.DEPLOY_STAGE.toLowerCase() === 'prod' ? process.env.RESHARMONICS_BOOKING_URL : process.env.RESHARMONICS_BOOKING_STAGING_URL;

    //   const startDate = Moment(this.moveinStartDate, 'YYYY-MM-DD').format('DD MM YYYY').replace(/ /g, '%2F');
    //   const endDate = this.moveinEndDate !== 'Invalid date' ?
    //     Moment(this.moveinEndDate, 'YYYY-MM-DD').format('DD MM YYYY').replace(/ /g, '%2F') :
    //     Moment(this.moveinStartDate, 'YYYY-MM-DD').add(3, 'M').format('DD MM YYYY').replace(/ /g, '%2F');

    //   const datefilter = `${startDate}+-+${endDate}`;
    //   const promoCode = this.values.voucher ? String(this.values.voucher) : '';

    //   const bookingModule = document.createElement('div');
    //   bookingModule.id = 'resharmonics-booking';
    //   bookingModule.innerHTML = `<button id="close-booking-module" aria-label="close-booking-module" class="btn--small btn--dark-green" style="position: fixed; top: 16px; right: 16px" @click="closeBookingModule">Close</button>`;

    //   const iframe = document.createElement('iframe');
    //   iframe.src = `${baseURL}?buildingId=${buildingId}&adultsAndChildren=${adults}+Adults+·+${children}+Children&guests=${guests}&datefilter=${datefilter}&promoCode=${promoCode}`;
    //   iframe.style = 'width: 100vw; height: 100vh;';
    //   bookingModule.appendChild(iframe);

    //   bookingModule.style = 'width: 100vw; height: 100vh;';
    //   document.body.prepend(bookingModule);

    //   document.body.classList.add('overflow-hidden');

    //   const closeButton = document.getElementById('close-booking-module');
    //   closeButton.addEventListener('click', () => this.closeBookingModule());

    //   const fixedHeader = document.getElementsByClassName('main-header')[0];
    //   fixedHeader.style = 'position: relative;';

    //   this.closed();
    // },
    // closeBookingModule() {
    //   const fixedHeader = document.getElementsByClassName('main-header')[0];
    //   if (fixedHeader) fixedHeader.style = '';
    //   document.body.classList.remove('overflow-hidden');

    //   const bookingModule = document.getElementById('resharmonics-booking');
    //   if (bookingModule) bookingModule.remove();
    // },
    beforeOpen(event) {
      if (event.params ) {
        if (event.params.bookingType) {
          this.bookingType = event.params.bookingType;
        }
        if (event.params && event.params.location) {
          this.location = event.params.location;
          this.values.location = event.params.location;
        }
      }
      this.showing = true;
    },
    beforeClose() {
      this.clearScrollLock();
      this.showing = false;
    },
    opened(event) {
      this.form.isSubmitted = false;
      this.form.step = 1;
      if (this.location) {
        this.values.location = this.location;
      }
      if (this.bookingType === TABNAME_SHORT_STAY) {
        setTimeout(()=> {
          this.selectShortStay();
        }, 1);
      } else {
        setTimeout(()=> {
          this.selectLongStay();
        }, 1);
      }

      this.$scrollLock.disablePageScroll(this.$el.querySelectorAll('.v--modal-box')[0]);

      const nodes = this.$el.querySelectorAll(this.FOCUSABLE_ELEMENTS);
      this.focusableElements = Object.keys(nodes).map((key) => nodes[key]);
      this.focusableElements[0].focus();

      this.shortStayBookings = this.bookingLocations.filter((location)=>(this.enabled(location, 'shortStayTc') || this.getDistributorId(location)));
      this.longStayBookings = this.bookingLocations.filter((location)=>(this.enabled(location, 'stay') || this.enabled(location, 'extraLongStay')));
      if (window.location.pathname.includes('/thank-you-')) {
        this.changeBookingType(TABNAME_LONG_STAY);
        this.retrieveAndSubmitLongStayInfo();
      }
    },
    reset() {
      this.form.isDirty = false;
      this.location=null;
      this.values={ ...this.initialValues };
    },
    closed() {
      this.clearScrollLock();
      this.showing = false;
      this.$modal.hide('booking');
      this.reset();

      if (window.location.pathname.includes('/thank-you-')) {
        this.$router.push('/');
      }
    },
    clearScrollLock() {
      this.$scrollLock.enablePageScroll(this.$el.querySelectorAll('.v--modal-box')[0]);
    },
    popHistory(state, e) {
      this.showing = false;

      this.$modal.hide('booking');
      this.$modal.hide('booking-stay');
    },
    getDistributorId(location) {
      if (location.link.bookingOptions && location.link.bookingOptions.distributorId !== null && location.link.bookingOptions.distributorId !== '') {
        return location.link.bookingOptions.distributorId;
      } else {
        return false;
      }
    },
    validate(element) {
      this.form.submitDisabled = true;
      this.form.isDirty = true;
      if (
        (this.bookingType == TABNAME_SHORT_STAY && this.$refs.location.hasValue) || 
        (this.bookingType == TABNAME_LONG_STAY && 
          this.$refs.firstname.isValid() &&
          this.$refs.lastname.isValid() &&
          this.$refs.phone.isValid() &&
          this.$refs.email.isValid() && 
          this.$refs.location.hasValue && 
          this.$refs.moveinDate.hasValue 
        )
      ) {
        this.form.submitDisabled = false;
        return true;
      } else {
        return false;
      }
    },
    submit() {
      if (this.validate()) {
        if (this.bookingType == TABNAME_SHORT_STAY) {
          this.form.isSubmitted = true;
          this.submitShortStay();
        } else {
          this.submitLongStay();
        }
      }
    },
    submitShortStay() {
      const eventData = {
        category: 'ShortStayBooking',
        action: 'Click',
        label: 'Submit-ToMewsFlow',
        property: this.values.location || null,
        value: null,
        event: 'clickedCta',
      };

      trackClickedCta(this.$gtm, eventData);

      const locationIndex = this.shortStayBookings.findIndex((location) => location.title == this.values.location);
      const currentLocation = this.shortStayBookings[locationIndex];

      if (this.enabled(currentLocation, 'shortStayTc')) {
        this.openBookingLongStay(currentLocation);
      } else if (this.getDistributorId(currentLocation)) {
        this.openBookingShortStay(currentLocation);
      }
      this.form.isSubmitted = false;
      this.form.step = 1;
      // this.reset();
    },
    submitLongStay() {
      // NOTE: UNCOMMENT FOR RESHARMONICS (AND REFACTOR INTO EXISTING HUBSPOT CODE)
      // if (this.resharmonicsMigrated.longStay && this.values.location === 'Old Oak') {
      //   this.openBookingModule(this.values.location);
      //   return;
      // }

      // const submittedInfo = {
      //   values: { ...this.values },
      //   moveinStartDate: this.moveinStartDate,
      // };
      // window.localStorage.setItem('longStaySubmission', JSON.stringify(submittedInfo));
      
      // const locationObj = this.longStayBookings.find((item) => (item.title === this.values.location));
      // if (locationObj) {
      //   const locationSlug = locationObj.link.contentCodename.replace('_', '-');
      //   this.$router.push(`thank-you-${locationSlug}`);
      // }

      const { location } = this.values;

      const requestFields = Object.entries(this.longStayValues).map((entry) => {
        let name = entry[0];
        let value = entry[1];

        if (location == 'Old Oak' && name === 'location') {
          name = 'development';
          value = 'The Collective Old Oak';
        }

        return {
          name,
          value,
        };
      });

      const requestBody = {
        'fields': requestFields,
        'context': {
          'hutk': Cookie.get('hubspotutk'),
          'pageUri': window.location.href,
          'pageName': window.document.title,
        },
        'legalConsentOptions': {
          'consent': { // Include this object when GDPR options are enabled
            'consentToProcess': true,
            'text': 'I agree to allow The Collective to store and process my personal data.',
            'communications': [
              {
                'value': true,
                'subscriptionTypeId': 999,
                'text': 'I agree to receive marketing communications from The Collective.',
              },
            ],
          },
        },
      };
      const accessToken = (location == 'Old Oak')
        ? process.env.HUBSPOT_APP_AUTHORIZATION_OO
        : process.env.HUBSPOT_APP_AUTHORIZATION_CW;

      this.$axios.post(
          this.formActionLink,
          requestBody,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            this.form.isSubmitted = true;

            const submittedInfo = {
              values: { ...this.values },
              moveinStartDate: this.moveinStartDate,
            };
            window.localStorage.setItem('longStaySubmission', JSON.stringify(submittedInfo));

            const locationObj = this.longStayBookings.find((item) => (item.title === this.values.location));

            if (locationObj) {
              const locationSlug = locationObj.link.contentCodename.replace('_', '-');
              this.$router.push(`thank-you-${locationSlug}`);
            }
          })
          .catch((error) => {
            console.error(util.inspect(error, { showHidden: false, depth: null }));
          });
    },
    retrieveAndSubmitLongStayInfo() {
      this.form.step = 2;
      this.bookingType = TABNAME_LONG_STAY;
      this.form.isSubmitted = true;
      const retrievedLongStayInfo = JSON.parse(window.localStorage.getItem('longStaySubmission'));

      if (retrievedLongStayInfo) {
        this.values = { ...retrievedLongStayInfo.values };
        this.moveinStartDate = retrievedLongStayInfo.moveinStartDate;
        window.localStorage.removeItem('longStaySubmission');
        const eventData = {
          category: 'LongStayBooking',
          action: 'Click',
          label: 'Submit',
          property: this.values.location || null,
          value: null,
          event: 'clickedCta',
        };
        trackClickedCta(this.$gtm, eventData);
        // Hubspot meeting URLs
        this.values.moveinDate = Moment(this.moveinStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss UTC');
        const hubspotMeetingURLs = {
          'Canary Wharf': process.env.HUBSPOT_MEETING_URL_CW,
          'Old Oak': process.env.HUBSPOT_MEETING_URL_OO,
        };
        this.hubspotMeetingURL = hubspotMeetingURLs[this.values.location];

        // 360 tour URLs
        const tourURLs = {
          'Canary Wharf': 'https://canarywharf360tour.thecollective.com',
          'Old Oak': 'https://oldoak360tour.thecollective.com',
        };
        this.tourURL=tourURLs[this.values.location];

        this.$axios.post(process.env.BOOKING_FORM_ENDPOINT_STAY, this.values, {
          headers: { Accept: 'application/javascript' },
        })
            .then((response) => {
              trackEnquiry(this.$gtm, this.values);
              this.form.step = 2;
            })
            .catch((error) => {
              console.error(util.inspect(error, { showHidden: false, depth: null }));
              this.form.step = 2;
            });
        // this.reset();
      }
    },

    openExternalBookingForm() {
      const {
        firstname,
        lastname,
        phone,
        email,
        location,
        moveinDate,
      } = this.values;

      const movein = Moment(moveinDate, 'Do MMM YYYY')
          .format('YYYY-MM-DD');

      const queryParams = new URLSearchParams({
        firstname,
        lastname,
        contactnumber: phone,
        email,
        location,
        movein,
      });

      const url = new URL(`?${queryParams.toString()}`, process.env.RERUMAPP_BOOKING_BASE_URL);

      window.open(url.toString(), '_blank');
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/modules/_booking.scss';
</style>
