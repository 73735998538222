<template>
  <section :class="{ 'cookie-banner--closed': !open }" class="cookie-banner">
    <div
        class="cookie-banner__block d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center"
    >
      <div class="cookie-banner__body push-v--mobile--small" v-html="cookieBannerBody" />
      <button class="cookie-banner__button btn--dark-green" @click="closeCookieBanner">
        {{ cookieBannerCloseButtonText }}<Arrow class="btn--arrow__icon btn--arrow__icon--orange" />
      </button>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { NAMESPACE, ACCEPT_COOKIE_BANNER } from '~/store/sitewide';

const { mapGetters, mapActions } = createNamespacedHelpers(NAMESPACE);

export default {
  data() {
    return {
      open: true,
    };
  },
  computed: {
    ...mapGetters(['cookieBannerBody', 'cookieBannerCloseButtonText']),
  },
  methods: {
    closeCookieBanner() {
      this[ACCEPT_COOKIE_BANNER]();
      this.open = false;
    },
    ...mapActions([ACCEPT_COOKIE_BANNER]),
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/modules/_cookie-banner.scss';
</style>
