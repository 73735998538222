import { apiCall, MULTIPLE } from '~/api';
import { processMultiple } from '~/utils/processor';
import { API_DEPTH } from '~/utils/constants';

export async function GetPosts(skip, postsPerPage, filter = {}) {
  const apiData = await apiCall({
    type: MULTIPLE,
    contentType: 'template___blog_post',
    language: 'en-GB',
    modifiers: {
      order: {
        key: 'date',
        direction: 'desc',
      },
      depth: API_DEPTH,
      skip,
      limit: postsPerPage,
      ...filter,
    },
    dataProcessor: (data) => {
      return processMultiple(data);
    },
  });

  return apiData;
}

export async function GetLatestPosts() {
  return await GetPosts(0, 3);
}
