<template>
  <div>
    <component :is="modules.LocationHero" v-if="locationHero" v-bind="Object.assign(locationHero, bookingOptions)" />
    <component :is="modules.AnchorLinks" v-if="anchorLinks" v-bind="anchorLinks" />
    <component :is="modules.LocationLookAround" v-if="lookAround" v-bind="lookAround" />
    <component :is="modules.RoomsPricing" v-if="roomsPricing" v-bind="roomsPricing" />
    <component :is="modules.RoomsListing" v-if="roomsListing" v-bind="roomsListing" />
    <component :is="modules.Offers" v-if="offers" v-bind="offers" />
    <component :is="modules.TermsAndConditions" v-if="termsAndConditions && termsAndConditions.title" v-bind="termsAndConditions" />

    <component :is="modules.BuildingPlan" v-if="buildingPlan" v-bind="buildingPlan" />
    <component :is="modules.CoLivingBenefits" v-if="coLivingBenefits" v-bind="coLivingBenefits" />
    <component :is="modules.SafetyCleanliness" v-if="safetyCleanliness" v-bind="safetyCleanliness" />
    
    <component :is="modules.EventsListing" v-if="eventsListing" v-bind="eventsListing" />
    <component :is="modules.CoWorkingListing" v-if="coWorkingListing" v-bind="coWorkingListing" />
    <component :is="modules.PrivateHireListing" v-if="privateHireListing" v-bind="privateHireListing" />
    <component :is="modules.CurrentResidents" v-if="ctaCarousel" v-bind="ctaCarousel" />
    <component :is="modules.RestaurantsListing" v-if="restaurantsListing" v-bind="restaurantsListing" />
    <!-- <component :is="modules.EventsPromo" v-if="eventsPromo" v-bind="eventsPromo" /> -->
    <component :is="modules.RequestInterest" v-if="requestInterest" v-bind="requestInterest" />
    <component :is="modules.FindUs" v-if="findUs" v-bind="findUs" />
    <component :is="modules.LocationMap" v-if="locationMap" v-bind="locationMap" />
    <component :is="modules.CoWorking" v-if="coWorking" v-bind="coWorking" />
    <component :is="modules.Faqs" v-if="faqs" :faqs="faqsArray" />
    <NewsletterSignup
        v-if="shouldShowNewsletter"
        :user-id="newsletterUserId"
        :list-id="newsletterListId"
        :email-field-placeholder="newsletterEmailFieldPlaceholder"
        :submit-button-text="newsletterSubmitButtonText"
        :newsletter-title="newsletterTitle"
        :portal-id="newsletterDetails.portalId"
        :form-id="newsletterDetails.formId"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import NewsletterSignup from '~/components/components/NewsletterSignup';
import moduleLoader from '~/utils/module-loader';
import { NAMESPACE, SET_CRISIS_NOTIFICATION_ALERT } from '~/store/sitewide';

import {
  location_hero as LocationHero,
  anchor_links as AnchorLinks,
  location_look_around as LocationLookAround,
  building_plan as BuildingPlan,
  events_listing as EventsListing,
  rooms_pricing as RoomsPricing,
  rooms_listing as RoomsListing,
  co_working_listing as CoWorkingListing,
  private_hire_listing as PrivateHireListing,
  restaurants_listing as RestaurantsListing,
  location_map as LocationMap,
  co_working as CoWorking,
  find_us as FindUs,
  request_interest as RequestInterest,
  co_living_benefits as CoLivingBenefits,
  current_residents as CurrentResidents,
  events_promo as EventsPromo,
  faqs as Faqs,
  terms_and_conditions as TermsAndConditions,
  safety_cleanliness as SafetyCleanliness,
  offers as Offers,
} from '~/.modules.json';

// Set modules on page

const modules = [
  LocationHero,
  AnchorLinks,
  LocationLookAround,
  BuildingPlan,
  EventsListing,
  RoomsListing,
  RoomsPricing,
  CoWorkingListing,
  PrivateHireListing,
  RestaurantsListing,
  FindUs,
  LocationMap,
  CoWorking,
  RequestInterest,
  CoLivingBenefits,
  CurrentResidents,
  EventsPromo,
  Faqs, 
  TermsAndConditions,
  SafetyCleanliness,
  Offers,
];

const { mapActions, mapGetters } = createNamespacedHelpers(NAMESPACE);

export default {
  components: {
    NewsletterSignup,
  },

  props: {
    locationHero: { type: Object, required: true, default: null },
    anchorLinks: { type: Object, required: false, default: null },
    lookAround: { type: Object, required: false, default: null },
    termsAndConditions: { type: Object, required: false, default: null },
    buildingPlan: { type: Object, required: false, default: null },
    eventsListing: { type: Object, required: false, default: null },
    roomsListing: { type: Object, required: false, default: null },
    roomsPricing: { type: Object, required: false, default: null },
    coWorkingListing: { type: Array, required: false, default: null },
    privateHireListing: { type: Array, required: false, default: null },
    restaurantsListing: { type: Object, required: false, default: null },
    eventsPromo: { type: Object, required: false, default: null },
    findUs: { type: Object, required: false, default: null },
    locationMap: { type: Object, required: false, default: null },
    coWorking: { type: Object, required: false, default: null },
    coLivingBenefits: { type: Object, default: null },
    requestInterest: { type: Object, required: false, default: null },
    bookingOptions: { type: Object, required: true, default: null },
    address: { type: String, required: false, default: null },
    ctaCarousel: { type: Object, default: null },
    title: { type: String, required: false, default: null },
    metaTitle: { type: String, required: false, default: null },
    metaDescription: { type: String, required: false, default: null },
    safetyCleanliness: { type: Object, default: null },
    offers: { type: Object, default: null },
    faqs: { type: Object, required: true, default: null },
    crisisNotificationAlert: { type: Object, required: false, default: null },
  },
  data: function() {
    return {
      faqsArray: null,
    };
  },

  computed: {
    ...mapGetters([
      'newsletterTitle',
      'newsletterUserId',
      'newsletterListId',
      'newsletterEmailFieldPlaceholder',
      'newsletterSubmitButtonText',
    ]),

    shouldShowNewsletter() {
      return this.newsletterDetails
        && !this.$route.fullPath.endsWith('paper-factory');
    },

    newsletterDetails() {
      if (this.$route.fullPath.endsWith('old-oak')) {
        return {
          portalId: '25596573',
          formId: '0fea1964-687e-4fea-a07e-0588539a9b7f',
        };
      }

      if (this.$route.fullPath.endsWith('canary-wharf')) {
        return {
          portalId: '139632336',
          formId: '6be32b98-e5ce-44e8-adf5-1f25b972c869',
        };
      }

      return null;
    },
  },

  beforeCreate() {
    this.modules = moduleLoader(modules);
  },
  created() {
    this.faqsArray = [this.faqs];
  },
  mounted() {
    this.$scrollEntrance.mount();
    this.SET_CRISIS_NOTIFICATION_ALERT({
      crisisNotificationAlert: this.crisisNotificationAlert,
    });
  },
  beforeDestroy() {
    this.$scrollEntrance.destroy();
  },
  methods: {
    ...mapActions([SET_CRISIS_NOTIFICATION_ALERT]),
  },
};
</script>
