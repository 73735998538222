<template>
  <div class="vcard">
    <span class="org vcard__address-line vcard__address-line--org">{{ org }}</span>
    <span class="street-address">
      <span class="vcard__address-line">{{ addressLineOne }}</span>
      <span v-if="addressLineTwo" class="vcard__address-line">{{ addressLineTwo }}</span>
    </span>
    <span class="locality vcard__address-line vcard__address-line--locality">{{ locality }}</span>
    <span class="postal-code vcard__address-line vcard__address-line--postcode ">{{ postcode }}</span>
    <span class="region vcard__address-line visually-hidden">{{ region }}</span>
    <span class="country vcard__address-line visually-hidden">{{ country }}</span>
    <span v-if="email" class="vcard__address-line vcard__address-line--tel-email">
      <a :href="`mailto:${email}`" class="email vcard__email">{{ email }}</a>
    </span>
    <span v-if="tel" class="vcard__address-line vcard__address-line--tel-email">
      <a :href="`tel:${tel}`" class="tel vcard__tel">{{ tel }}</a>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    org: { type: String, required: true },
    addressLineOne: { type: String, required: true },
    addressLineTwo: { type: String, default: '' },
    locality: { type: String, required: true },
    region: { type: String, required: true },
    postcode: { type: String, required: true },
    country: { type: String, required: true },
    email: { type: String, default: '' },
    tel: { type: String, default: '' },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/elements/_vcard.scss';
</style>
