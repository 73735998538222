<template>
  <div>
    <component :is="modules.BlogListingHero" v-if="blogListingHero" v-bind="blogListingHero" />
    <component :is="modules.BlogListing" v-bind="blogListing" />
  </div>
</template>

<script>
import moduleLoader from '~/utils/module-loader';
import { blog_listing_hero as BlogListingHero, blog_listing as BlogListing } from '~/.modules.json';

// Set modules on page

const modules = [BlogListingHero, BlogListing];

export default {
  components: {},
  props: {
    blogListingHero: { type: Object, required: true, default: null },
    blogListing: { type: Object, required: true, default: null },
    
    title: { type: String, required: false, default: null },
    metaTitle: { type: String, required: false, default: null },
    metaDescription: { type: String, required: false, default: null },
  },
  beforeCreate() {
    this.modules = moduleLoader(modules);
  },
  mounted() {
    this.$scrollEntrance.mount();
  },
  beforeDestroy() {
    this.$scrollEntrance.destroy();
  },
};
</script>
