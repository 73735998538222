<template>
  <component :is="modules.Faqs" v-if="faqs" :faqs="isArray" class="faqs--standalone" />
</template>

<script>
import moduleLoader from '~/utils/module-loader';
import { faqs as Faqs } from '~/.modules.json';

// Set modules on page

const modules = [Faqs];

export default {
  props: {
    faqs: { type: Object, required: true },

    title: { type: String, required: false, default: null },
    metaTitle: { type: String, required: false, default: null },
    metaDescription: { type: String, required: false, default: null },
  },
  computed: {
    isArray() {
      return Array.isArray(this.faqs) ? this.faqs : [this.faqs];
    },
  },
  beforeCreate() {
    this.modules = moduleLoader(modules);
  },
  mounted() {
    this.$scrollEntrance.mount();
  },
  beforeDestroy() {
    this.$scrollEntrance.destroy();
  },
};
</script>
