export const focusElements = {
  data() {
    return {
      focusableElements: [],
      FOCUSABLE_ELEMENTS: [
        'a[href]',
        'area[href]',
        'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
        'select:not([disabled]):not([aria-hidden])',
        'textarea:not([disabled]):not([aria-hidden])',
        'button:not([disabled]):not([aria-hidden])',
        'iframe',
        'object',
        'embed',
        '[contenteditable]',
        '[tabindex]:not([tabindex^="-"])',
      ],
    };
  },
  mounted() {
    // console.log(`focus elements mixin accessed`);
  },
  methods: {
    moveFocusBack() {
      const focusedItemIndex = this.getFocusedItemIndex();

      this.focusableElements[
        focusedItemIndex - 1 >= 0 ? focusedItemIndex - 1 : this.focusableElements.length - 1
      ].focus();
    },
    moveFocusForward() {
      // get index of currently focussed element on focusable elements array
      const focusedItemIndex = this.getFocusedItemIndex();
      const nextIndex = focusedItemIndex + 1 < this.focusableElements.length ? focusedItemIndex + 1 : 0;
      this.focusableElements[nextIndex].focus();
    },
    getFocusedItemIndex() {
      return this.focusableElements.indexOf(document.activeElement);
    },
  },
};
