<template>
  <div>
    <header :class="{ 'main-header--fixed': scrolling,'main-header--fixed__open':primaryNavSecondOpen && desktop,'main-header__white': isWhiteHeader && desktop && !scrolling && !primaryNavSecondOpen , 'offset-by-crisis-bar': crisisNotificationAlert && crisisNotificationAlert.body && !primaryNavSecondOpen }" class="main-header">
      <div class="main-header__inner">
        <div class="main-header__inner-container container-fluid">
          <div class="main-header__content">
            <div class="main-header__controls">
              <button
                  v-if="!isBookingHeader"
                  aria-pressed="false"
                  class="main-header__navigation-control navigation-control"
                  @click="OPEN_PRIMARY_NAVIGATION"
              >
                <span class="navigation-control__text">{{ showPrimaryNavigationText }}</span>
                <BurgerIcon v-if="!scrolling && !primaryNavSecondOpen" class="navigation-control__icon" />
                <BurgerIconDark v-if="scrolling || primaryNavSecondOpen" class="navigation-control__icon" />
              </button>
              <nuxt-link :to="home" class="main-header__branding">
                <span class="main-header__logo">
                  <img v-show="!scrolling && !primaryNavSecondOpen" :alt="logoAltTag" class="main-header__branding-icon" src="~/assets/icons/logo.svg?v=1">
                  <img v-show="scrolling || primaryNavSecondOpen" :alt="logoAltTag" class="main-header__branding-icon" src="~/assets/icons/logo_dark.svg?v=1">
                  <!-- <TextLogo class="main-header__title" />
                  <span class="main-header__title-text">The Collective</span> -->
                </span>
              </nuxt-link>
              <a :href="`${this.$route.path}#main-content`" class="main-header__skip-link visually-hidden" tabindex="0" @keyup.stop="">Skip to main content</a>
            </div>
            <nav class="main-header__navigation-wrapper" role="navigation">
              <NavigationList
                  v-if="!isBookingHeader"
                  :items="primaryNavigationTopLevel"
                  :item-class="`main-header__navigation-item`"
                  :link-class="`main-header__navigation-link`"
                  :link-active-class="`active`"
                  :max-depth="1"
                  :click="handleLinkWithChildrenClick"
                  class="main-header__navigation"
                  :is-primary-navigation-open="primaryNavSecondOpen"
                  :open-nav-index="openNavIndex"
              />
              <button
                  v-if="!isBookingHeader"
                  class="main-header__book btn--arrow btn--small"
                  :class="{'btn--dark-green':!isWhiteHeader || scrolling || primaryNavSecondOpen,'btn--white':isWhiteHeader && !scrolling && !primaryNavSecondOpen}"
                  aria-label="booking-options"
                  tabindex="0"
                  @click.prevent="$modal.show('booking')"
                  @click="collectData"
              >
                {{ book.text }}
                <!-- <Arrow
                    class="btn--arrow__icon btn--arrow__icon--right btn--arrow__icon--orange"
                    icon="arrow-right"
                /> -->
              </button>
              <no-ssr>
                <nuxt-link
                    v-if="isBookingHeader && lastPath"
                    :to="lastPath"
                    class="main-header__book btn--arrow btn--dark-green btn--small"
                >
                  {{ bookingHeaderBackText }}
                  <Arrow
                      class="btn--arrow__icon btn--arrow__icon--right btn--arrow__icon--orange"
                      icon="arrow-right"
                  />
                </nuxt-link>
              </no-ssr>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <no-ssr>
      <transition
          :enter-class="`navigation-transition--inactive`"
          :enter-active-class="`navigation-transition--active`"
          :leave-active-class="`navigation-transition--active`"
          :leave-class="`navigation-transition--inactive`"
          :leave-to-class="`navigation-transition--inactive`"
          name="navigation-transition"
      >
        <PrimaryNavigation
            v-if="primaryNavigationOpen"
            :address="address"
            :primary-items-top-level="primaryNavigation"
            :primary-items-second-level="primaryNavigationSecondLevel"
            :secondary-items="primaryNavigationSecondary"
            :close-navigation="resetAndClose"
            :open-navigation-level="OPEN_PRIMARY_NAVIGATION_LEVEL"
            :open-top-level="RESET_PRIMARY_NAVIGATION"
            :book="book"
            :handle-link-with-children-click="handleLinkWithChildrenClick"
            :close-primary-navigation-text="closePrimaryNavigationText"
            :back-to-menu-text="backToMenuText"
            :title="primaryItemSecondLevelMeta.title"
            :secondary-text="primaryItemSecondLevelMeta.secondaryText"
            :open-nav-index="openNavIndex"
            :is-primary-navigation-open="primaryNavSecondOpen"
        />
      </transition>
    </no-ssr>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { LG } from '~/utils/constants';
import debounce from 'lodash.debounce';
import PrimaryNavigation from '../components/PrimaryNavigation';
import NavigationList from '../elements/NavigationList';
import { clickedCta } from '~/utils/gtm-events.js';

import {
  NAMESPACE,
  OPEN_PRIMARY_NAVIGATION,
  CLOSE_PRIMARY_NAVIGATION,
  OPEN_PRIMARY_NAVIGATION_LEVEL,
  RESET_PRIMARY_NAVIGATION,
  SET_CRISIS_NOTIFICATION_ALERT,
} from '../../store/sitewide';
import BurgerIcon from '../../assets/icons/new/menu-icon.svg?inline';
import BurgerIconDark from '../../assets/icons/new/menu_dark.svg?inline';

const { mapGetters, mapActions } = createNamespacedHelpers(NAMESPACE);

export default {
  components: {
    PrimaryNavigation,
    NavigationList,
    BurgerIcon,
    BurgerIconDark,
  },
  props: {
    template: { type: String, required: true },
  },
  data() {

    return {
      scrolling: false,
      primaryNavSecondOpen: false,
      desktop: false,
      isOpen: false,
      openNavIndex: -1,
      // isWhiteHeader: true,
    };
  },

  computed: {
    ...mapGetters([
      'primaryNavigationTopLevel',
      'primaryNavigation',
      'primaryNavigationSecondLevel',
      'primaryItemSecondLevelMeta',
      'primaryNavigationOpen',
      'showPrimaryNavigation',
      'closePrimaryNavigation',
      'currentLanguage',
      'address',
      'primaryNavigationSecondary',
      'book',
      'home',
      'logoAltTag',
      'showPrimaryNavigationText',
      'closePrimaryNavigationText',
      'backToMenuText',
      'lastPath',
      'bookingHeaderBackText',
      'crisisNotificationAlert',
      'isWhiteHeader',
    ]),
    isBookingHeader() {
      if (this.template === 'book') {
        return true;
      }

      return false;
    },
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeMethod);
  },
  mounted() {
    window.addEventListener('resize', this.tabIndex);
    this.tabIndex();
    this.handleResponsiveSwitch();
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 0 || document.body.scrollTop > 0) {
        this.scrolling = true;
      } else {
        this.scrolling = false;
      }
    };

    this.resizeMethod = debounce(() => this.handleResponsiveSwitch(), 100);
    this.handleResponsiveSwitch();

    window.addEventListener('resize', this.resizeMethod);
  },
  methods: {
    ...mapActions([
      OPEN_PRIMARY_NAVIGATION,
      CLOSE_PRIMARY_NAVIGATION,
      OPEN_PRIMARY_NAVIGATION_LEVEL,
      RESET_PRIMARY_NAVIGATION,
      SET_CRISIS_NOTIFICATION_ALERT,
    ]),
    resetActiveClass() {

      const items = this.primaryNavigation.length;
      for (let index = 0; index < items; index++) {
        const node = document.getElementById('link_index_'+ index);
        if (node) {
          if (this.openNavIndex >= 0 && index === this.openNavIndex) {
            node.classList.add(`active`);
          } else {
            node.classList.remove(`active`);
          }
        }

      }

    },
    handleResponsiveSwitch() {
      if (window.innerWidth >= LG) {
        this.desktop = true;
      } else {
        this.desktop = false;
      }
    },
    resetAndClose() {
      this.CLOSE_PRIMARY_NAVIGATION();
      this.resetActiveClass();
      this.primaryNavSecondOpen = false;

    },
    handleLinkWithChildrenClick(node, e, depth, index) {

      const { url, children, external } = node;

      if (external) {
        window.open(url);
      } else if (children.length > 0) {
        if ( !this.primaryNavSecondOpen || this.openNavIndex !== index) {
          this.OPEN_PRIMARY_NAVIGATION_LEVEL({ depth, index });
          this.openNavIndex = index;
          this.primaryNavSecondOpen = true;
        } else {
          this.desktop && this.CLOSE_PRIMARY_NAVIGATION();
          this.primaryNavSecondOpen = false;
        }
        // this.primaryNavSecondOpen
        this.resetActiveClass();

      } else {
        if (this.desktop) {
          this.CLOSE_PRIMARY_NAVIGATION();
          this.openNavIndex = index;
          this.primaryNavSecondOpen = false;
          this.resetActiveClass();

          if ( this.$router.currentRoute && this.$router.currentRoute.path === url) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }
        }
        this.$router.push({ path: url });
      }
    },
    tabIndex() {
      const element = document.querySelector('.main-header__navigation-control');

      if (window.innerWidth >= LG) {
        element.setAttribute('tabindex', '-1');
      } else {
        element.setAttribute('tabindex', '0');
      }
    },
    collectData() {
      clickedCta(this.$gtm, 'book', 'homepage', null, null);
    },
    closeCrisisAlert() {
      this.SET_CRISIS_NOTIFICATION_ALERT({
        crisisNotificationAlert: {},
      });
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/modules/_main-header.scss';
@import '~/assets/scss/atomic/elements/_navigation-control.scss';
</style>
