<template>
  <component :is="modules.Privacy" v-if="privacy" v-bind="privacy" />
</template>

<script>
import moduleLoader from '~/utils/module-loader';
import { privacy as Privacy } from '~/.modules.json';

// Set modules on page

const modules = [Privacy];

export default {
  props: {
    privacy: { type: Object, required: true },

    title: { type: String, required: false, default: null },
    metaTitle: { type: String, required: false, default: null },
    metaDescription: { type: String, required: false, default: null },
  },
  beforeCreate() {
    this.modules = moduleLoader(modules);
  },
  mounted() {
    this.$scrollEntrance.mount();
  },
  beforeDestroy() {
    this.$scrollEntrance.destroy();
  },
};
</script>
