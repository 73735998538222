<template>
  <div>
    <component :is="modules.GenericHero" v-if="genericHero" v-bind="genericHero" />
    <!-- <component :is="modules.AnchorLinks" v-if="anchorLinks" v-bind="anchorLinks" /> -->
    <component :is="modules.OurValues" v-if="ourValues" v-bind="ourValues" />
    <component :is="modules.WhatWeDo" v-if="whatWeDo" v-bind="whatWeDo" />
    <component :is="modules.GenericContent" v-if="genericContent" v-bind="genericContent" />
    <component :is="modules.TeamBanner" v-if="teamBanner" v-bind="teamBanner" />
  </div>
</template>

<script>
import moduleLoader from '~/utils/module-loader';

import {
  generic_hero as GenericHero,
  // anchor_links as AnchorLinks,
  our_values as OurValues,
  what_we_do as WhatWeDo,
  generic_content as GenericContent,
  team_banner as TeamBanner,
} from '~/.modules.json';

// Set modules on page

// const modules = [GenericHero, AnchorLinks, GenericContent, OurValues, WhatWeDo, TeamBanner];
const modules = [GenericHero, GenericContent, OurValues, WhatWeDo, TeamBanner];

export default {
  props: {
    genericHero: { type: Object, required: true, default: null },
    // anchorLinks: { type: Object, required: true, default: null },
    genericContent: { type: Object, default: null },
    ourValues: { type: Object, required: true, default: null },
    whatWeDo: { type: Object, required: true, default: null },
    teamBanner: { type: Object, required: true, default: null },

    title: { type: String, required: false, default: null },
    metaTitle: { type: String, required: false, default: null },
    metaDescription: { type: String, required: false, default: null },
  },
  beforeCreate() {
    this.modules = moduleLoader(modules);
  },
  mounted() {
    this.$scrollEntrance.mount();
  },
  beforeDestroy() {
    this.$scrollEntrance.destroy();
  },
};
</script>
