<template>
  <div class="spinner">
    <div class="bounce1" />
    <div class="bounce2" />
    <div class="bounce3" />
  </div>
</template>
<script>
export default {
    
};
</script>
<style lang="scss">
@import '~/assets/scss/atomic/components/_loading.scss';
</style>
